import { styled, Box } from '@mui/material';
import React from 'react';

const StyledChip = styled(Box)<{ resolved?: boolean; bgColor?: string }>`
  font-size: 1rem;
  padding: 0.438rem 1.125rem;
  border-radius: 0.563rem;
  font-weight: 600;
  color: ${({ resolved, theme }) =>
    resolved ? theme.palette.success.light : theme.palette.error.light};
  background-color: ${({ bgColor, theme }) => bgColor || theme.palette.common.white};
  position: absolute;
  top: 1rem;
  right: 2.188rem;
`;

export const ResolvedChip: React.FC<{ resolved: boolean }> = ({ resolved }) => (
  <StyledChip resolved={resolved}>{resolved ? 'Resolved' : 'Unresolved'}</StyledChip>
);
