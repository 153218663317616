import { Box, styled } from '@mui/material';
import React from 'react';

export interface ListItemStyleTypes {
  bgcolor?: boolean;
  width?: number;
  justifycontent?: string;
}

const ListItemContainer = styled(Box)<ListItemStyleTypes>`
  width: ${({ width }) => (width ? `${width}%` : '100%')};
  display: flex;
  align-items: center;
  justify-content: ${({ justifycontent }) => justifycontent || 'space-between'};
  border-radius: 0.563rem;
  padding: 0.563rem 1.125rem;
  cursor: pointer;
  background: ${({ theme, bgcolor }) => (!bgcolor ? theme.palette.common.white : '#05B60C')};
  box-shadow: 0px 0.25rem 0.625rem rgba(0, 0, 0, 0.15);
  margin: 1% 0;
  position: relative;

  @media (max-width: 768px) {
    margin: 3% 0;
  }
`;

interface ListItemProps {
  bgcolor?: boolean;
  onClick?: any;
  justifycontent?: string;
}

const getColors = (
  bgcolor: boolean | undefined,
): {
  color: string;
  backgroundColor: string;
} => ({
  color: bgcolor ? '#fff' : 'rgb(0,0,0)',
  backgroundColor: bgcolor ? '#05B60C' : '#fff',
});

export const ListItem: React.FC<ListItemProps> = ({
  children,
  bgcolor,
  justifycontent,
  onClick,
}) => (
  <ListItemContainer justifycontent={justifycontent} onClick={onClick} style={getColors(bgcolor)}>
    {children}
  </ListItemContainer>
);
