import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { MailType } from '../types/Mail';

interface UserState {
  student: any;
  user: any;
  studentTransactions: any;
  notification: any;
  vendor: any;
  mail: any;
}

const initialState: UserState = {
  student: null,
  user: null,
  notification: null,
  studentTransactions: null,
  vendor: null,
  mail: null,
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setStudent: (state, action: PayloadAction<any>) => {
      state.student = action.payload;
    },
    updateStudent: (state, action: PayloadAction<any>) => {
      state.student = {
        ...state.student,
        ...action.payload,
      };
    },
    setUser: (state, action: PayloadAction<any>) => {
      state.user = action.payload;
    },
    setStudentTransactions: (state, action: PayloadAction<any>) => {
      state.studentTransactions = action.payload;
    },
    setNotification: (state, action: PayloadAction<any>) => {
      state.notification = action.payload;
    },
    setVendor: (state, action: PayloadAction<any>) => {
      state.vendor = action.payload;
    },
    setMail: (state, action: PayloadAction<MailType>) => {
      state.mail = action.payload;
    },
    changeMailOffer: (state, action: { payload: 'accepted' | 'denied' }) => {
      state.mail = {
        ...state.mail,
        offerResponse: action.payload,
      };
    },
  },
});

export const {
  setStudent,
  setUser,
  updateStudent,
  setStudentTransactions,
  setNotification,
  setVendor,
  setMail,
  changeMailOffer,
} = userSlice.actions;
export default userSlice.reducer;
