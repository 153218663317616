import { useEffect, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import {
  createSupportTicket,
  getAllSupportTickets,
  getSupportTicket,
  postSupportReply,
} from '../api';
import { useNotification } from './useNotification';

export const useSupport = (page?: number, successCb?: () => void) => {
  const [tickets, setTickets] = useState([]);
  const { data, isLoading, refetch } = useQuery(['supportTickets', page], () =>
    getAllSupportTickets(page),
  );
  const { notify, notifyError } = useNotification();

  const mutation = useMutation(createSupportTicket, {
    onSuccess: () => {
      successCb?.();
      notify({ message: 'Ticket added successfully' });
      refetch();
    },
    onError: (e: any) => {
      notifyError(e.message);
      console.log(e);
    },
  });

  useEffect(() => {
    if (data) {
      setTickets(data.data.data.tickets);
    }
  }, [data]);

  return {
    tickets,
    mutation,
    isLoading,
    refetch,
  };
};

export interface SupportTicketType {
  agent: any;
  createdBy: any;
  date: number;
  exportExternal: boolean;
  id: string;
  messages: Array<any>;
  resolved: boolean;
}

export const useSupportTicket = (ticketId: string, cb?: () => void) => {
  const [supportTicket, setSupportTicket] = useState<SupportTicketType | null>(null);
  const { data, isLoading } = useQuery(['supportTicket', ticketId], () =>
    getSupportTicket(ticketId),
  );
  const queryClient = useQueryClient();
  const { notify, notifyError } = useNotification();

  const mutation = useMutation(postSupportReply, {
    onSuccess: () => {
      queryClient.invalidateQueries(['supportTicket', ticketId], {
        refetchActive: true,
      });
      notify({ message: 'Successfully replied on support ticket' });
      cb?.();
    },
    onError: (e: any) => {
      notifyError(e.message);
    },
  });

  useEffect(() => {
    if (data) {
      setSupportTicket(data.data.data);
    }
  }, [data]);

  return {
    supportTicket,
    isLoading,
    mutation,
  };
};
