import { Box, Collapse, styled } from '@mui/material';
import React from 'react';

export const LoginShape = () => (
  <Box>
    <svg
      width={window.innerWidth}
      height="340"
      viewBox={`0 0 ${window.innerWidth} 340`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        filter="url(#filter0_d_597_1659)"
        shapeRendering="geometricPrecision"
        transform="scale(2.5, 0.7)"
      >
        <path
          d="M0 -183V139.083V273.541C0 273.541 159.198 209.626 326.861 216.106C494.524 222.586 564.975 283.114 724.446 307.414C883.917 331.714 1024 322.141 1024 322.141V139.083V-183H0Z"
          fill="url(#paint0_linear_597_1659)"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_597_1659"
          x="-10"
          y="-187"
          width="1044"
          height="527"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="6" />
          <feGaussianBlur stdDeviation="5" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_597_1659" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_597_1659"
            result="shape"
          />
        </filter>
        <linearGradient
          id="paint0_linear_597_1659"
          x1="548.932"
          y1="542.192"
          x2="1035.39"
          y2="-275.492"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#54B1E5" />
          <stop offset="1" stopColor="#0C84FC" />
        </linearGradient>
      </defs>
    </svg>
  </Box>
);

export const LoginShapeAnim: React.FC<{ animate: boolean }> = ({ animate }) => (
  <Collapse in={animate} collapsedSize={333}>
    <svg
      width={window.innerWidth}
      height={!animate ? '340' : window.innerHeight}
      viewBox={`0 0 ${window.innerWidth} 340`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d_597_1659)" transform="scale(2.5, 0.8)">
        <path
          d="M0 -183V139.083V273.541C0 273.541 159.198 209.626 326.861 216.106C494.524 222.586 564.975 283.114 724.446 307.414C883.917 331.714 1024 322.141 1024 322.141V139.083V-183H0Z"
          fill="#ECECEC"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_597_1659"
          x="-10"
          y="-187"
          width="1044"
          height="527"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="6" />
          <feGaussianBlur stdDeviation="5" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_597_1659" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_597_1659"
            result="shape"
          />
        </filter>
        <linearGradient
          id="paint0_linear_597_1659"
          x1="548.932"
          y1="542.192"
          x2="1035.39"
          y2="-275.492"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#54B1E5" />
          <stop offset="1" stopColor="#0C84FC" />
        </linearGradient>
      </defs>
    </svg>
  </Collapse>
);

const ShapeContainer = styled(Box)<{
  lower?: number;
}>`
  transform: translate(0, ${({ lower }) => (lower ? lower : -35)}vh);
  position: absolute;
  top: 0;
  left: 0;
`;
const ShapeContainer2 = styled(Box)`
  transform: translate(0, 1vh);
  z-index: 10;
`;

export const NewShape: React.FC<{ lower?: number }> = ({ lower }) => (
  // <ShapeContainer lower={lower}>
  <svg
    width="100%"
    viewBox="0 0 1024 340"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{
      transform: `translate(0, ${lower ? lower.toString() : '-30'}%`,
      position: 'absolute',
      top: '0',
      left: '0',
      zIndex: '1',
    }}
  >
    <g filter="url(#filter0_d_597_1659)">
      <path
        d="M0 -183V139.083V273.541C0 273.541 159.198 209.626 326.861 216.106C494.524 222.586 564.975 283.114 724.446 307.414C883.917 331.714 1024 322.141 1024 322.141V139.083V-183H0Z"
        fill="#0C84FC"
      />
    </g>
    <defs>
      <filter
        id="filter0_d_597_1659"
        x="-10"
        y="-187"
        width="1044"
        height="527"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="6" />
        <feGaussianBlur stdDeviation="5" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_597_1659" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_597_1659"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
  // </ShapeContainer>
);

export const NewShape2: React.FC<{ animate: boolean }> = ({ animate }) => (
  <Collapse>
    <ShapeContainer2>
      <svg
        width="100%"
        height="527"
        viewBox="0 0 1024 340"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#filter0_d_597_1659)">
          <path
            d="M0 -183V139.083V273.541C0 273.541 159.198 209.626 326.861 216.106C494.524 222.586 564.975 283.114 724.446 307.414C883.917 331.714 1024 322.141 1024 322.141V139.083V-183H0Z"
            fill="#000"
          />
        </g>
        <defs>
          <filter
            id="filter0_d_597_1659"
            x="-10"
            y="-187"
            width="1044"
            height="527"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="6" />
            <feGaussianBlur stdDeviation="5" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_597_1659" />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_597_1659"
              result="shape"
            />
          </filter>
        </defs>
      </svg>
    </ShapeContainer2>
  </Collapse>
);
