import React, { useEffect } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

import { Layout } from '../components/Layout/Layout';
import { Loading } from '../components/Loading';
import { useAuth } from '../hooks/useAuth';

export const Dashboard = () => {
  const { userLoading } = useAuth();

  if (userLoading) return <Loading />;

  return (
    <Layout>
      <Outlet />
    </Layout>
  );
};
