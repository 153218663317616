import { Box, FormControl, Input, InputLabel, Select, styled } from '@mui/material';
import React from 'react';

import { CustomText } from '../pages/StudentInfo/StudentInfo.style';

const InputContainer = styled(Box)<{ width?: string; alignitems?: string }>`
  width: ${({ width }) => width || '100%'};
  display: flex;
  align-items: ${({ alignitems }) => alignitems || 'center'};
  justify-content: center;
  flex-direction: column;

  & .Mui-focused {
    outline-color: blue;
  }
`;

export const CustomInput = styled(Input)<{ padding?: string; marginInput?: string }>`
  width: 100%;
  border-radius: 0.625;
  padding: ${({ padding }) => (padding ? padding : '0.25rem 0.625rem')};
  outline: 1px solid ${({ theme }) => theme.color.borderColor};
  margin: ${({ marginInput }) => marginInput};
`;

interface InputFieldProps {
  info?: string;
  id?: string;
  alignitems?: string;
  width?: string;
  padding?: string;
  margin?: string;
  [x: string]: any;
}

export const InputField: React.FC<InputFieldProps> = ({
  info,
  id,
  width,
  alignitems,
  padding,
  margin,
  ...rest
}) => (
  <InputContainer width={width} alignitems={alignitems}>
    <InputLabel htmlFor={id}>
      <CustomText color="rgb(0,0,0)" fontsize={1.2} bold={700}>
        {info}
      </CustomText>
    </InputLabel>
    <CustomInput
      id={id}
      disableUnderline
      padding={padding}
      marginInput={margin || '4% 0'}
      {...rest}
    />
  </InputContainer>
);

const SelectContainer = styled(Box)<{ width?: string; alignitems?: string }>`
  width: ${({ width }) => width || '100%'};
  display: flex;
  align-items: ${({ alignitems }) => alignitems || 'center'};
  justify-content: center;
  flex-direction: column;

  & .MuiFormControl-root {
    width: 100%;
  }
`;

export const CustomSelect = styled(Select)<{
  pty?: boolean;
  padding?: string;
  marginInput?: string;
}>`
  height: 2.5rem;
  width: 100%;
  border-radius: 0.625rem;
  color: ${({ theme, pty }) => (pty ? theme.palette.grey[500] : theme.palette.common.black)};
  padding: ${({ padding }) => (padding ? padding : '0.25 0.625rem !important')};
  border: none;
  outline: none;
  margin: ${({ marginInput }) => marginInput};
  [role='button'] {
    padding: ${({ padding }) => (padding ? padding : '0.25rem 0.625rem !important')};
  }
`;

export const SelectField: React.FC<InputFieldProps> = ({
  info,
  id,
  width,
  alignitems,
  padding,
  margin,
  children,
  ...rest
}) => (
  <SelectContainer width={width} alignitems={alignitems}>
    <InputLabel>
      <CustomText color="rgb(0,0,0)" fontsize={1.2} bold={700}>
        {info}
      </CustomText>
    </InputLabel>
    <FormControl>
      <CustomSelect
        labelId={id}
        disableUnderline
        marginInput={margin || '4% 0'}
        pty={!rest.value}
        displayEmpty
        {...rest}
      >
        {children}
      </CustomSelect>
    </FormControl>
  </SelectContainer>
);
