import { useTheme } from '@mui/material';
import { Link } from 'react-router-dom';

import { ListItem } from '../../common';
import { Layout } from '../../components/Layout/Layout';
import { ROUTES } from '../../constants';
import { useAuth } from '../../hooks/useAuth';
import { AllTransactionsHeader } from '../AllTransactions/AllTransactionsHeader';
import { CustomText } from '../StudentInfo/StudentInfo.style';
import { SettingsItem, settingsItems } from './Settings.constants';
import { SettingsItemsContainer } from './Settings.style';

export const Settings = () => {
  const theme = useTheme();
  const { logout } = useAuth();

  return (
    <Layout bgColor={theme.color.lightGrey}>
      <AllTransactionsHeader lnk={ROUTES.STUDENT} text="Settings" noTabs />
      <SettingsItemsContainer>
        {settingsItems.map(({ desc, image, route }: SettingsItem) => {
          if (desc === 'Logout') {
            return (
              <ListItem
                key={desc}
                justifycontent="flex start"
                onClick={() => {
                  window.localStorage.removeItem('ac_token');
                  window.location.href = '/login';
                }}
              >
                {image}
                <CustomText fontSize={1.4} margin="0 2%">
                  {desc}
                </CustomText>
              </ListItem>
            );
          }
          return (
            <Link to={`../${route}` || ''} key={desc}>
              <ListItem justifycontent="flex start">
                {image}
                <CustomText fontSize={1.4} margin="0 2%">
                  {desc}
                </CustomText>
              </ListItem>
            </Link>
          );
        })}
      </SettingsItemsContainer>
    </Layout>
  );
};
