import { format } from 'date-fns';
import moment from 'moment';
import React from 'react';

import { DATE_FORMAT } from '../../constants';
import { Flex } from '../AddCard/AddCard.style';
import { CustomText } from '../StudentInfo/StudentInfo.style';
import {
  AvatarImage,
  DateContainer,
  Person,
  PersonInfo,
  SubjectContainer,
  TicketItemContainer,
} from './SupportTicket.style';

interface TicketItemProps {
  date: number;
  body: string;
  name: string;
  email: string;
  subject: string;
  image?: string;
}

export const TicketItem: React.FC<TicketItemProps> = ({
  name,
  email,
  subject,
  date,
  body,
  image,
}) => (
  <TicketItemContainer>
    <Person>
      <AvatarImage src={image} />
      <PersonInfo>
        <CustomText bold={700}>{name}</CustomText>
        <CustomText>{email}</CustomText>
      </PersonInfo>
    </Person>
    <SubjectContainer>
      <Flex>
        <CustomText bold={700}>Subject:</CustomText>
        <CustomText margin="0 0.4rem">{subject}</CustomText>
      </Flex>
      <DateContainer>
        <CustomText>{moment.unix(date).fromNow()}</CustomText>
      </DateContainer>
    </SubjectContainer>
    <CustomText>{body}</CustomText>
  </TicketItemContainer>
);
