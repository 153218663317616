import { useEffect, useRef } from 'react';
import { CardData } from '../api';
import { useAppSelector } from '../store/store';
import { useCard } from './useCard';

export const useSquare = (schoolId: string) => {
  const details = useRef<string | null>(null);
  const cardData = useRef<CardData | null>(null);
  const payment = window?.Square;
  const { user } = useAppSelector((state) => state.user);
  const applicationId = user.schools.find((item: any) => item.id === schoolId)?.squareData?.appId;
  const locationId = user.schools.find((item: any) => item.id === schoolId)?.squareData?.locationId;
  const { mutation, fundsMutation } = useCard(schoolId);

  const handleVerifyBuyer = async (payments: any) => {
    if (details.current) {
      const verificationDetails = {
        intent: 'STORE',
        billingContact: {
          familyName: user.name.split(' ')[1],
          givenName: user.name.split(' ')[0],
          email: user.email,
          phone: user.phoneNumber,
          city: user.city,
        },
      };
      const cnon = details.current;
      const verification = await payments.verifyBuyer(cnon, verificationDetails);
      return verification;
    }
  };

  const cardTokenizeResponseReceived = async (value: any) => {
    if (value.status === 'OK') {
      details.current = value.token;
      const verf = await handleVerifyBuyer(payment?.payments(applicationId, locationId));
      cardData.current = {
        schoolId,
        cardNonce: details.current,
        verificationToken: verf.token,
      };
      mutation.mutate(cardData.current);
    }
  };

  const handleVerifyBuyerCharge = async (payments: any, cnon: string, amount: string) => {
    console.log(applicationId, locationId);
    const verificationDetails: any = {
      intent: 'CHARGE',
      amount: amount,
      currencyCode: 'GBP',
      billingContact: {
        familyName: user.name.split(' ')[1],
        givenName: user.name.split(' ')[0],
        email: user.email,
        phone: user.phoneNumber,
        city: user.city,
      },
    };
    const verification: any = await payments.verifyBuyer(cnon, verificationDetails, (res: any) => {
      console.log(res);
    });
    return verification;
  };

  return {
    applicationId,
    locationId,
    cardTokenizeResponseReceived,
    handleVerifyBuyerCharge,
    payments: payment?.payments(applicationId, locationId),
    fundsMutation,
  };
};
