import { useTheme } from '@mui/material';
import { useRef } from 'react';
import { useLocation } from 'react-router-dom';

import { ResolvedChip } from '../../common/ResolvedChip';
import { Layout } from '../../components/Layout/Layout';
import { Loading, LoadingFull } from '../../components/Loading';
import { ShapeHeader } from '../../components/ShapeHeader';
import { ROUTES } from '../../constants';
import { useSupportTicket } from '../../hooks/useSupport';
import { getPath } from '../../utils';
import { CustomText } from '../StudentInfo/StudentInfo.style';
import { TicketsContainer } from './SupportTicket.style';
import { TicketItem } from './TicketItem';
import { TicketReply } from './TicketReply';

export const SupportTicket = () => {
  const theme = useTheme();
  const { pathname } = useLocation();
  const { supportTicket, isLoading } = useSupportTicket(getPath(pathname));
  const ref: any = useRef();

  if (isLoading) return <Loading />;

  if (!supportTicket) return null;

  return (
    <Layout
      noScroll="hidden"
      onScroll={(e: any) => (ref.current.scrollTop = ref.current.scrollTop + e.deltaY)}
    >
      <ShapeHeader bannerText="Ticket Details" back={ROUTES.SUPPORT} />

      <ResolvedChip resolved={supportTicket.resolved} />

      <TicketsContainer ref={ref}>
        {supportTicket &&
          supportTicket?.messages.map((ticket: any) => (
            <TicketItem
              name={ticket.from.name}
              email={ticket.from.email}
              image={ticket.from.photo}
              subject={ticket.subject}
              body={ticket.body}
              date={ticket.date}
            />
          ))}

        {supportTicket &&
          (!supportTicket.resolved ? (
            <TicketReply />
          ) : (
            <CustomText color={theme.color.blue} bold={700} margin="1rem">
              Ticket is resolved!
            </CustomText>
          ))}
      </TicketsContainer>
    </Layout>
  );
};
