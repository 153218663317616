import React, { useState } from 'react';
import {
  VendorContainer,
  VendorItemContainer,
  TextContainer,
  Slider,
  SliderText,
  SliderContent,
  ButtonContainer,
  IconCircle,
} from './Vendors.style';
import { Button, CustomAvatar } from '../../common';
import { CustomText } from '../StudentInfo/StudentInfo.style';
import { SliderContainer } from '../Vendor/Vendor.style';
import { Confirm } from '../../svg/Confirm';
import { CancelCircleX } from '../../svg/CancelCircleX';
import { useAppSelector } from '../../store/store';

export const VendorItme: React.FC<{ vendorInfo?: any; limit?: number; limitVendor: any }> = ({
  vendorInfo,
  limitVendor,
  limit,
}) => {
  const [edit, setEdit] = useState(false);
  const [limitLine, setLimitLine] = useState<number | number[]>(() => (limit ? limit : 9));
  const { student } = useAppSelector((state) => state.user);
  const onChange = (_: Event, newValue: number | number[]) => setLimitLine(newValue);
  const [limitShow, setLimitShow] = useState<number | number[]>(() => (limit ? limit : 0));

  console.log(vendorInfo, 'STUDENT');
  const onSubmit = async () => {
    limitVendor({
      studentId: student.uid,
      vendorId: vendorInfo.id,
      limit: limitLine,
    });
    setLimitShow(limitLine);
    setEdit(false);
  };

  return (
    <VendorItemContainer>
      <VendorContainer>
        <CustomAvatar image={<img src={vendorInfo.photo} alt="" />} />
        <TextContainer>
          <CustomText fontsize={1.6}>{vendorInfo.name}</CustomText>
          <CustomText variant="h6">
            Weekly Limit: {limitShow ? '£' + limitShow : 'no limit'}
          </CustomText>
        </TextContainer>
      </VendorContainer>
      {!edit && (
        <Button content="Set Weekly Spending Limit" width={30} onClick={() => setEdit(true)} />
      )}
      {edit && (
        <>
          <SliderContent>
            <Slider>
              <SliderText>
                <CustomText>Limit</CustomText>
                <CustomText variant="h6">£{limitLine}</CustomText>
              </SliderText>
              <SliderContainer
                max={250}
                defaultValue={limit ? limit : 9}
                value={limitLine}
                onChange={onChange}
              />
            </Slider>
          </SliderContent>
          <ButtonContainer>
            <IconCircle invert onClick={() => setEdit(false)}>
              <CancelCircleX />
            </IconCircle>
            <IconCircle onClick={onSubmit}>
              <Confirm />
            </IconCircle>
          </ButtonContainer>
        </>
      )}
    </VendorItemContainer>
  );
};
