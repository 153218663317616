import { useState } from "react";

export const useForm = <T>(defaultValues: T) => {
  const [values, setValues] = useState(defaultValues);

  const onChange = (event: any) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    })
  }

  return {
    values,
    setValues,
    onChange,
  }
}
