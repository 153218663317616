import { Box, Slider, styled } from '@mui/material';

export const TrackerContainer = styled(Box)`
  display: flex;
  align-items: flex-end;
  justify-content: center;
  flex-direction: column;
  padding: 0.875rem 6.5rem;
  margin: 2% auto;

  @media (max-width: 768px) {
    padding: 1.25rem 1.813rem;

    button {
      width: 100%;
      margin-top: 2%;
    }
  }
`;

export const SliderContainer = styled(Slider)`
  color: ${({ theme }) => theme.color.lightGrey};
  margin-bottom: 2%;

  & .MuiSlider-thumb {
    background: linear-gradient(50.23deg, #54b1e5 9.71%, #0c84fc 108.89%);
  }

  & .MuiSlider-track {
    background: linear-gradient(50.23deg, #54b1e5 9.71%, #0c84fc 108.89%);
  }
`;

export const Flex = styled(Box)`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 0 0.25rem;
  margin-bottom: 2%;
`;
