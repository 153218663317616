import { Close } from "@mui/icons-material";
import { AlertProps, IconButton, Snackbar } from "@mui/material";
import React from "react";

import { useNotification } from "../../hooks/useNotification";
import { useAppSelector } from "../../store/store";
import { StyledMuiAlert } from "./Notification.styled";

const Alert = React.forwardRef<HTMLDivElement, AlertProps>((props, ref) => (
  <StyledMuiAlert elevation={6} ref={ref} variant="filled" {...props} />
));

export const Notification = () => {
  const notification = useAppSelector(state => state.user.notification);
  const { notify } = useNotification();

  if(!notification) {
    return null;
  }

  const handleClose = (_: any, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    notify(null);
  };

  const { message, hideDuration, type, Action } = notification;

  const ActionWrapper = (
    <>
      {Action && <Action />}
      <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
        <Close fontSize="small" />
      </IconButton>
    </>
  );

  return (
    <Snackbar
      open={!!notification}
      autoHideDuration={hideDuration || 6000}
      onClose={handleClose}
      message={message}
      action={ActionWrapper}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
    >
      <Alert onClose={handleClose} severity={type ?? 'success'} action={ActionWrapper}>
        {message}
      </Alert>
    </Snackbar>
  )
}
