import { Avatar, Box, styled } from '@mui/material';

import { CustomInput } from '../../common/Input';

export const TicketsContainer = styled(Box)`
  width: 100%;
  padding: 20vh 10vw;
  display: flex;
  align-items: center;
  flex-direction: column;
  overflow-y: auto;
  position: relative;

  @media (max-width: 768px) {
    margin-top: 3.875rem;
  }
`;

export const TicketItemContainer = styled(Box)`
  width: 90%;
  padding: 1rem 0;
  border-bottom: 1px solid #dcdcdc;
`;

export const Person = styled(Box)`
  width: 30%;
  display: flex;
`;

export const AvatarImage = styled(Avatar)`
  height: 2.875rem;
  width: 2.875rem;
`;

export const PersonInfo = styled(Box)`
  height: 100%;
  flex: 0 0 75%;
  margin-left: 4%;
`;

export const DateContainer = styled(Box)`
  display: flex;
  justify-content: flex-end;
  flex: 0 0 35%;
`;

export const SubjectContainer = styled(Box)`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin: 2% 0;
`;

export const TicketReplyContainer = styled(Box)`
  height: 16vh;
  width: 90%;
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  padding: 1rem 0;
`;

export const ReplyInput = styled(CustomInput)`
  border: none;
  outline: none;
  background-color: ${({ theme }) => theme.palette.common.white};
  box-shadow: 0px 0.25rem 0.625rem rgba(0, 0, 0, 0.15);
  margin: 2% 0;
`;
