import React from 'react';

export const Info = () => {
  return (
    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11 0C4.92039 0 0 4.91975 0 11C0 17.0796 4.91975 22 11 22C17.0796 22 22 17.0802 22 11C22 4.92039 17.0802 0 11 0ZM11 20.4651C5.78093 20.4651 1.53489 16.2191 1.53489 11C1.53489 5.78093 5.78093 1.53489 11 1.53489C16.2191 1.53489 20.4651 5.78093 20.4651 11C20.4651 16.2191 16.2191 20.4651 11 20.4651Z"
        fill="#0C84FC"
      />
      <path
        d="M11.0001 8.74878C10.5762 8.74878 10.2327 9.0924 10.2327 9.5162V15.8092C10.2327 16.2331 10.5763 16.5766 11.0001 16.5766C11.4239 16.5766 11.7675 16.233 11.7675 15.8092V9.51624C11.7675 9.0924 11.4239 8.74878 11.0001 8.74878Z"
        fill="#0C84FC"
      />
      <path
        d="M11.0001 5.42334C10.5762 5.42334 10.2327 5.76696 10.2327 6.19076V7.11171C10.2327 7.53555 10.5763 7.87913 11.0001 7.87913C11.4239 7.87913 11.7675 7.53551 11.7675 7.11171V6.19076C11.7675 5.76696 11.4239 5.42334 11.0001 5.42334Z"
        fill="#0C84FC"
      />
    </svg>
  );
};
