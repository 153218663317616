import { Box, useTheme } from '@mui/material';
import { useAppSelector } from '../../store/store';
import { useState } from 'react';
import { ListItem } from '../../common';
import { useAllTransactions } from '../../hooks/useAllTransactions';
import { isWithinInterval, set, formatDistance } from 'date-fns';
import { groupByDate } from '../../utils/dateSeparate';

import { Layout } from '../../components/Layout/Layout';
import { ROUTES } from '../../constants';
import { CustomText } from '../StudentInfo/StudentInfo.style';
import { AllTransactionsContainer, AllTransactionsBox } from './AllTransaction.style';
import { AllTransactionsHeader } from './AllTransactionsHeader';
import moment from 'moment';

export const AllTransactions = () => {
  const theme = useTheme();
  const [activeTab, setActiveTab] = useState<'School bill' | 'wallet'>('wallet');
  const state = useAppSelector((state) => state.user);

  const { transactions } = useAllTransactions(state.student.uid, activeTab);

  return (
    <Layout bgColor={theme.color.lightGrey}>
      <AllTransactionsHeader
        lnk={ROUTES.STUDENT}
        text="Transactions"
        activeTab={activeTab}
        setActiveTab={setActiveTab}
      />
      <AllTransactionsBox>
        <AllTransactionsContainer>
          {transactions &&
            groupByDate(transactions).map((item) => {
              return (
                <>
                  <div style={{ position: 'sticky', top: 0, zIndex: 10 }}>
                    <ListItem bgcolor>
                      <CustomText fontsize={1.26} bold={700}>
                        {item.date}
                      </CustomText>
                    </ListItem>
                  </div>
                  <div>
                    {item.transactions.map((transaction: any, index: number) => {
                      return (
                        <ListItem key={index}>
                          <Box>
                            <CustomText bold={700}>{transaction.name}</CustomText>
                            <CustomText fontsize={0.75}>
                              {moment.unix(transaction.date).fromNow()}
                            </CustomText>
                          </Box>
                          <CustomText
                            fontsize={1.4}
                            color={
                              transaction.paidType === 'received'
                                ? theme.palette.success.light
                                : theme.palette.error.light
                            }
                          >
                            {`${transaction.paidType === 'received' ? '+' : '-'} £${(
                              transaction.price / 100
                            ).toFixed(2)}`}
                          </CustomText>
                        </ListItem>
                      );
                    })}
                  </div>
                </>
              );
            })}
        </AllTransactionsContainer>
      </AllTransactionsBox>
    </Layout>
  );
};
