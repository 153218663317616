import { Divider, useTheme } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useDispatch } from 'react-redux';
import { getCreditCard } from '../../api';
import { Button } from '../../common';
import { InputField } from '../../common/Input';
import { useNotification } from '../../hooks/useNotification';
import { useSquare } from '../../hooks/useSquare';
import { useStudentTransactions } from '../../hooks/useStudentTransactions';
import { CustomText } from '../../pages/StudentInfo/StudentInfo.style';
import { useAppSelector } from '../../store/store';
import { updateStudent } from '../../store/user';
import { Loading } from '../Loading';
import { FieldsContainer, StudentModalContainer, StudentModalContent } from './StudentModal.style';

interface StudentModalProps {
  availableBalance: number;
  open: boolean;
  onClose: () => void;
  school: any;
}

export const StudentModal: React.FC<StudentModalProps> = ({
  availableBalance,
  open,
  onClose,
  school,
}) => {
  const dispatch = useDispatch();
  const { data } = useQuery(['card', school], () => getCreditCard(school.id));
  const [loading, setLoading] = useState(false);
  const { notifySuccess, notifyError } = useNotification();
  const [choosenCard, setChoosenCard] = useState('');
  const [selectedAmount, setSelectedAmount] = useState<number>(0);
  const theme = useTheme();
  const { handleVerifyBuyerCharge, payments, fundsMutation } = useSquare(school.id);
  const { student } = useAppSelector((state) => state.user);

  const onChange = (event: any) => {
    if (isNaN(event.target.value)) {
      return;
    }
    setSelectedAmount(event.target.value);
  };

  const addFunds = async () => {
    setLoading(true);
    const res = await handleVerifyBuyerCharge(payments, choosenCard, selectedAmount.toString());
    setLoading(false);
    return res.token;
  };

  useEffect(() => {
    console.log(school);
    console.log(data);
    if (data) {
      setChoosenCard(data?.data?.data?.card?.id);
    }
  }, [data]);

  const submit = async () => {
    if (isNaN(selectedAmount)) {
      notifyError('Please enter the number!');
      return;
    }
    if (!choosenCard) {
      console.log(choosenCard);
      notifyError("You don't have card saved!");
      return;
    }
    await fundsMutation.mutateAsync({
      studentId: student.uid,
      amount: selectedAmount * 100,
      verificationToken: await addFunds(),
    });
    dispatch(updateStudent({ availableBalance: availableBalance + selectedAmount * 100 }));
    onClose();
  };

  // if (fundsMutation.isLoading || !choosenCard) return <Loading />;

  return (
    <StudentModalContainer
      open={open}
      onClose={() => {
        if (!fundsMutation.isLoading && !loading) {
          onClose();
        }
      }}
    >
      <StudentModalContent>
        <FieldsContainer alignItems="center" justifyContent="center">
          <CustomText fontsize={1.2} bold={700} color={theme.color.commonBlue}>
            Add Funds
          </CustomText>
        </FieldsContainer>
        <Divider />
        <FieldsContainer height={75} justifyContent="space-between">
          <InputField
            padding="0.563rem"
            placeholder="£0000"
            onChange={onChange}
            value={selectedAmount}
          />
          <Button content="Submit" loading={fundsMutation.isLoading || loading} onClick={submit} />
          <Button
            content="Cancel"
            disableElevation
            bgColor={theme.palette.grey[400]}
            onClick={() => {
              if (!fundsMutation.isLoading && !loading) {
                onClose();
              }
            }}
          />
        </FieldsContainer>
      </StudentModalContent>
    </StudentModalContainer>
  );
};
