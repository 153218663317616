import React from 'react';
import { useLocation } from 'react-router-dom';

import { Button } from '../../common';
import { LoadingFull } from '../../components/Loading';
import { useForm } from '../../hooks/useForm';
import { useNotification } from '../../hooks/useNotification';
import { useSupportTicket } from '../../hooks/useSupport';
import { getPath } from '../../utils';
import { ReplyInput, TicketReplyContainer } from './SupportTicket.style';

export interface ReplyMessage {
  ticketId: string;
  message: string;
}

export const TicketReply = () => {
  const { pathname } = useLocation();
  const { notifyError } = useNotification();
  const { values, setValues, onChange } = useForm<ReplyMessage>({
    ticketId: getPath(pathname),
    message: '',
  });
  const reset = () => setValues({ ...values, message: '' });

  const { mutation } = useSupportTicket(getPath(pathname), reset);

  const onSubmit = (a: any) => {
    a.preventDefault();
    if (!values.message) {
      notifyError('Please enter message!');
      return;
    }
    mutation.mutate(values);
  };

  return (
    <TicketReplyContainer component="form" onSubmit={onSubmit}>
      <ReplyInput
        multiline
        minRows={6}
        disableUnderline
        padding="0.688rem"
        name="message"
        value={values.message}
        onChange={onChange}
      />
      <Button content="SEND" disable={!values.message} width={27} type="submit" />
      {mutation.isLoading && <LoadingFull />}
    </TicketReplyContainer>
  );
};
