import { createTheme, ThemeOptions } from "@mui/material";
import "@fontsource/montserrat";

declare module '@mui/material/styles' {
  interface Theme {
    background: {
      blue: string;
    }
    color: {
      lightGrey: string;
      borderColor: string;
      red: string;
      blue: string;
      lightBlue: string;
      commonBlue: string;
      divider?: string;
    }
  }
  interface ThemeOptions {
    background: {
      blue: string;
    }
    color?: {
      lightGrey?: string;
      borderColor?: string;
      red?: string;
      blue?: string;
      lightBlue?: string;
      commonBlue?: string;
      divider?: string;
    }
  }
}

export const theme: ThemeOptions = createTheme({
  typography: {
    fontFamily: "Montserrat",
  },
  background: {
    blue: 'linear-gradient(50.23deg, #54B1E5 9.71%, #0C84FC 108.89%)',
  },
  color: {
    lightGrey: '#ECECEC',
    borderColor: '#ABABAB',
    red: '#ED5555',
    blue: '#0C84FC',
    lightBlue: '#0093EB',
    commonBlue: '#0091FF',
    divider: '#e3e3e3',
  },
});
