import { ChevronLeft } from '@mui/icons-material';
import { BackArrow } from '../../svg/BackArrow';
import React from 'react';
import { Link } from 'react-router-dom';

import { BannerText } from '../../components/Logo';
import { ShapeContainer } from '../Login/Login.style';
import { IconContainer, VendorsBannerMsg, HeaderContainer } from './Vendors.style';
import { IconButton } from '@mui/material';

export const VendorHeader: React.FC<{ back?: string }> = ({ back }) => (
  <HeaderContainer>
    <Link to={back || '/'}>
      <IconButton>
        <BackArrow />
      </IconButton>
    </Link>
    <VendorsBannerMsg>
      <BannerText variant="h4" bold={700}>
        Vendors List
      </BannerText>
      <BannerText variant="h5">
        Select vendor that you want to restrict your student to make purchases or limit
        <br />
        purchases of some products
      </BannerText>
    </VendorsBannerMsg>
    <div></div>
  </HeaderContainer>
);
