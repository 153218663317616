import { Box } from '@mui/material';
import React from 'react';

import { CustomAvatar } from '../../common';
import { BannerText } from '../../components/Logo';
import { StudentAvatarContainer } from './StudentInfo.style';

export const StudentDetails: React.FC<{ student?: any }> = ({ student }) => {
  console.log(student);
  return (
    <StudentAvatarContainer>
      <CustomAvatar image={<img src={student.imageUrl} alt="" />} />
      <Box>
        <BannerText variant="h5" bold={700}>
          {student.name}
        </BannerText>
        <BannerText variant="h6" sx={{ mt: 3 }}>
          {student.groups[0]}
        </BannerText>
        <BannerText variant="h6">Year: {student.schoolYear}</BannerText>
      </Box>
    </StudentAvatarContainer>
  );
};
