import { Box, styled, Typography } from '@mui/material';

export const Header = styled(Box)<{ width?: number; justifycontent?: string }>`
  width: ${({ width }) => (width ? `${width}%` : '100%')};
  height: 30vh;
  display: flex;
  align-items: center;
  justify-content: ${({ justifycontent }) => (justifycontent ? justifycontent : 'space-between')};
  padding: 0px 2.563rem;
  position: relative;
  z-index: 2;

  @media (max-width: 768px) {
    width: 100%;
    padding: 0 1rem;

    h5 {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
`;

export const StudentContainer = styled(Box)`
  width: 100%;
  max-width: 1400px;
  display: flex;
  margin: auto;
  flex: 1;
  padding: 2.563rem;
  position: relative;
  z-index: 2;

  @media (max-width: 768px) {
    flex-direction: column;
    padding: 2.563rem 1rem;
  }
`;

export const StudentInfoContainer = styled(Box)`
  display: flex;
  flex: 3;
  flex-direction: column;

  a {
    text-decoration: none;
  }
`;

export const StudentAvatarContainer = styled(Box)`
  height: 27%;
  width: 100%;
  display: flex;

  div:nth-of-type(2) {
    margin-left: 4%;
  }

  @media (max-width: 768px) {
    margin-bottom: 4%;
  }
`;

export const CardFinance = styled(Box)`
  width: 90%;
  aspect-ratio: 2/1;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  border-radius: 1rem;
  padding: 1rem;
  color: ${({ theme }) => theme.palette.common.white};
  background-color: ${({ theme }) => theme.color.blue};
  position: relative;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

interface NameLogoCardProps {
  height?: string;
  width?: string;
  alignItems?: string;
  justifyContent?: string;
  column?: boolean;
}

export const NameLogoCard = styled(Box)<NameLogoCardProps>`
  height: ${({ height }) => height || '16%'};
  width: ${({ width }) => width || '100%'};
  display: flex;
  align-items: ${({ alignItems }) => alignItems || 'stretch'};
  justify-content: ${({ justifyContent }) => justifyContent || 'space-between'};
  flex-direction: ${({ column }) => column && 'column'};

  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const StudentTransactionsContainer = styled(Box)`
  flex: 5;
  font-size: 1.25rem;
  position: relative;

  a {
    color: ${({ theme }) => theme.color.lightBlue};
  }

  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const CustomText = styled(Typography)<{
  fontsize?: number;
  bold?: number;
  color?: string;
  margin?: string;
}>`
  font-size: ${({ fontsize }) => (fontsize ? `${fontsize}rem` : '1rem')};
  font-weight: ${({ bold }) => bold || '400'};
  color: ${({ color }) => color};
  margin: ${({ margin }) => margin};
  text-decoration: none;
  a {
    text-decoration: none;
  }
`;

export const TransactionTabContainer = styled(Box)`
  display: flex;
  text-align: center;
  justify-content: center;
  gap: 8rem;
`;
export const TransactionHeader = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
`;
export const TransactionTab = styled(Box)<{ active: boolean }>`
  opacity: ${({ active }) => (active ? 1 : 0.5)};
  color: white;
  font-weight: 700;
  border-bottom: 2px solid white;
  font-size: 1.7rem;
  cursor: pointer;
  padding: 0 1rem;
  transition: all 0.8 ease;
`;
