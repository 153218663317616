import { useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { ListItem } from '../../common';
import { Loading } from '../../components/Loading';
import { ShapeHeader } from '../../components/ShapeHeader';
import { ROUTES } from '../../constants';
import { useMail } from '../../hooks/useMail';
import { setMail } from '../../store/user';
import { MailType } from '../../types/Mail';
import { CustomText } from '../StudentInfo/StudentInfo.style';
import { SupportItemsContainer } from '../Support/Support.style';
import { MailItem } from './MailItem';
import { Layout } from '../../components/Layout/Layout';
import { groupByDate } from '../../utils/dateSeparate';
import { useAppSelector } from '../../store/store';
import { useStudents } from '../../hooks/useStudents';

export const MailPage = () => {
  const { students } = useStudents();
  const { student, user } = useAppSelector((state) => state.user);
  const { mails, isLoading, answerMail } = useMail(student ? student.uid : null, 1);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const ref: any = useRef();

  const selectMail = (mail: MailType) => {
    dispatch(setMail(mail));
    navigate(ROUTES.MAIL_INBOX);
  };

  if (isLoading) return <Loading />;

  console.log(mails);

  return (
    <Layout onScroll={(e: any) => (ref.current.scrollTop = ref.current.scrollTop + e.deltaY)}>
      <ShapeHeader bannerText="Mail" back={ROUTES.STUDENT} />
      <SupportItemsContainer ref={ref}>
        {groupByDate(mails).map((items) => (
          <>
            <ListItem bgcolor>
              <CustomText fontsize={1.26} bold={700}>
                {items.date}
              </CustomText>
            </ListItem>
            {items.transactions.map((item: MailType) => {
              console.log(item, 'MAIL');
              return (
                <MailItem
                  key={item.id}
                  sender={item.from.name}
                  imageUrl={item.from.imageUrl}
                  subject={item.subject}
                  date={item.date}
                  onClick={() => selectMail(item)}
                />
              );
            })}
          </>
        ))}
      </SupportItemsContainer>
    </Layout>
  );
};
