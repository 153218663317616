import { Typography, useTheme } from '@mui/material';
import React, { useRef } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { CustomAvatar } from '../../common';

import { Layout } from '../../components/Layout/Layout';
import { Loading } from '../../components/Loading';
import { ROUTES } from '../../constants';
import { useVendors } from '../../hooks/useVendors';
import { useAppSelector } from '../../store/store';
import { FlexCenter } from '../PaymentMethod/PaymentMethod.style';
import { VendorHeader } from './VendorHeader';
import { VendorsContaienr } from './Vendors.style';
import { VendorItme } from './VendorItme';

export interface Vendor {
  name: string;
  image: string;
}

export const Vendors = () => {
  const state = useAppSelector((state) => state.user.student);
  const { vendors, isLoading, limitVendor } = useVendors(state.uid);
  const ref: any = useRef();

  return (
    <Layout
      bgColor="white"
      onScroll={(e: any) => (ref.current.scrollTop = ref.current.scrollTop + e.deltaY)}
    >
      <VendorHeader back={ROUTES.STUDENT} />
      {isLoading && <Loading />}
      <VendorsContaienr
        ref={ref}
        onWheel={(e: any) => (ref.current.scrollTop = ref.current.scrollTop)}
      >
        {vendors.length > 0 ? (
          vendors.map(({ vendor, limit }: any) => (
            <VendorItme vendorInfo={vendor} limitVendor={limitVendor} limit={limit} />
          ))
        ) : (
          <FlexCenter>
            <Typography variant="h3" color="error">
              No available vendors
            </Typography>
          </FlexCenter>
        )}
      </VendorsContaienr>
    </Layout>
  );
};
