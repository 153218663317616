import { useState, useEffect } from "react";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { getVendorsList, setVendorLimit } from "../api";
import { useNotification } from './useNotification';

export const useVendors = (studentId?: string) => {
  const [vendors, setVendors] = useState([]);
  const { notify, notifyError } = useNotification();

  const { data, isLoading } = useQuery(
    ['vendors', studentId],
    () => getVendorsList(studentId || ''),
  );
  const queryClient = useQueryClient();
  const mutations = useMutation(setVendorLimit, {
    onSuccess: () => {
      queryClient.invalidateQueries(['vendors', studentId], {
        refetchActive: true,
      });
      notify({ message: 'Vendor limit setted successfully' });
    },
    onError: (e: any) => {
      notifyError(e.message);
    },
  })

  useEffect(() => {
    if(data) {
      setVendors(data.data.data);
    }
  }, [data]);

  const limitVendor = (data: any) => {
    mutations.mutate(data);
  }

  return {
    vendors,
    isLoading,
    postLoading: mutations.isLoading,
    limitVendor,
  }
}
