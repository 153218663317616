import React, { useEffect } from "react";
import { useQuery } from "react-query";
import { useDispatch } from "react-redux";

import { getUser } from "../api";
import { Loading } from "../components/Loading";
import { useNotification } from "../hooks/useNotification";
import { setStudent, setUser } from "../store/user";

export const User = () => {
  const dispatch = useDispatch();
  const { notify } = useNotification();
  const { data, isLoading } = useQuery('users', getUser, {
    onSuccess: () => {
      console.log('Success');
      notify('Success');
    },
    onError: (e: any) => {
      notify(e.message);
    }
  });

  useEffect(() => {
    if(data) {
      dispatch(setUser(data.data.user));
      dispatch(setStudent(data.data.student));
    }
  }, [dispatch, data])

  if(isLoading) return <Loading />;

  return (
    <h4>{JSON.stringify(data)}</h4>
  )
}
