import { MenuItem } from '@mui/material';
import React from 'react';

import { Button } from '../../common';
import { InputField, SelectField } from '../../common/Input';
import { Layout } from '../../components/Layout/Layout';
import { LoadingFull } from '../../components/Loading';
import { ShapeHeader } from '../../components/ShapeHeader';
import { ROUTES } from '../../constants';
import { useForm } from '../../hooks/useForm';
import { useNotification } from '../../hooks/useNotification';
import { useSupport } from '../../hooks/useSupport';
import { useAppSelector } from '../../store/store';
import { Flex } from '../AddCard/AddCard.style';
import { Container } from './CreateSupport.style';

export interface SupportTicket {
  subject: string;
  message: string;
  exportExternal: boolean;
  schoolId: string;
}

export const CreateSupport = () => {
  const { user } = useAppSelector((state) => state.user);
  const { notifyError } = useNotification();
  const { values, setValues, onChange } = useForm<SupportTicket>({
    subject: '',
    message: '',
    exportExternal: false,
    schoolId: '',
  });

  const formReset = () => {
    setValues({
      subject: '',
      message: '',
      exportExternal: false,
      schoolId: '',
    });
  };

  const { mutation } = useSupport(1, formReset);

  const onSubmit = (a: any) => {
    a.preventDefault();
    if (!values.subject || !values.message || !values.schoolId) {
      notifyError('Please fill in all details!');
      return;
    }
    mutation.mutate(values);
  };

  return (
    <Layout>
      <ShapeHeader
        bannerText="Create Support Ticket"
        back={ROUTES.SUPPORT}
        desc="Support tickets are handled internally, usually by your school's IT staff"
      />

      <Container component="form" onSubmit={onSubmit}>
        <Flex justifyContent="space-between">
          <InputField
            info="Subject"
            id="subject"
            width="47%"
            alignitems="flex-start"
            margin="2% 0"
            placeholder="Enter here"
            name="subject"
            value={values.subject}
            onChange={onChange}
          />
          <SelectField
            info="Select School"
            id="school"
            width="47%"
            alignitems="flex-start"
            margin="2% 0"
            name="schoolId"
            value={values.schoolId}
            onChange={onChange}
          >
            <MenuItem value="" disabled>
              Select school
            </MenuItem>
            {user.schools.map((school: any) => (
              <MenuItem key={school.id} value={school.id}>
                {school.name}
              </MenuItem>
            ))}
          </SelectField>
        </Flex>

        <InputField
          info="Message"
          id="message"
          alignitems="flex-start"
          multiline
          minRows={6}
          padding="0.625rem"
          margin="1% 0"
          placeholder="Enter here"
          name="message"
          value={values.message}
          onChange={onChange}
        />

        <Button content="Submit" width={25} type="submit" />
      </Container>
      {mutation.isLoading && <LoadingFull />}
    </Layout>
  );
};
