import { Box, styled } from '@mui/material';
import React from 'react';

const CollegeHatContainer = styled(Box)`
  height: 5.063rem;
  width: 5.063rem;
  position: relative;
  z-index: 9999;
  display: flex;
  justify-content: flex-end;

  svg {
    width: 80%;
    height: 80%;
  }

  svg:nth-of-type(2) {
    transform: translate(15%, 67%);
  }
`;

interface HatOneProps {
  height?: number;
  width?: number;
  boxwidth?: number;
  boxheight?: number;
}

const CollegeHatSVG: React.FC<HatOneProps> = ({ height, width, boxheight, boxwidth }) => (
  <svg width="31" height="21" viewBox="0 0 31 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M22.2119 5.40465L22.448 5.50614C22.5478 5.54907 22.6516 5.56934 22.7537 5.56934C23.0541 5.56934 23.3399 5.39369 23.4661 5.10031C23.6352 4.70711 23.4535 4.25125 23.0603 4.08223L22.8242 3.9807C22.4309 3.81163 21.9752 3.99333 21.8061 4.38653C21.637 4.77977 21.8187 5.23559 22.2119 5.40465Z"
      fill="white"
    />
    <path
      d="M29.0211 11.6181V7.15538C29.0211 6.72735 28.6742 6.38038 28.2461 6.38038H14.9648C14.5367 6.38038 14.1898 6.72735 14.1898 7.15538C14.1898 7.58341 14.5367 7.93038 14.9648 7.93038H24.4812L14.9648 12.0217L3.64565 7.15538L14.9648 2.28904L20.0455 4.47333C20.4387 4.64236 20.8945 4.46066 21.0636 4.06743C21.2326 3.67423 21.0509 3.21838 20.6577 3.04935L15.2708 0.733418C15.0754 0.649408 14.854 0.649408 14.6586 0.733418L1.3773 6.44339C1.0928 6.56572 0.908386 6.84569 0.908386 7.15538C0.908386 7.46507 1.0928 7.74508 1.3773 7.86737L14.6587 13.5773C14.7564 13.6193 14.8606 13.6403 14.9648 13.6403C15.0689 13.6403 15.1732 13.6193 15.2708 13.5773L22.5806 10.4346L22.5808 15.7974C18.3152 19.7439 11.6142 19.7439 7.34871 15.7974L7.34875 14.4294C7.34875 14.0014 7.00179 13.6544 6.57375 13.6544C6.14576 13.6544 5.79875 14.0014 5.79875 14.4293L5.79871 16.1294C5.79871 16.3376 5.88253 16.5371 6.03125 16.6829C8.43073 19.0346 11.6033 20.3297 14.9647 20.3297C18.3261 20.3297 21.4988 19.0346 23.8982 16.683C24.0469 16.5372 24.1308 16.3377 24.1308 16.1294L24.1306 9.76825L27.4711 8.3321V11.6181C26.8397 11.9115 26.4007 12.5514 26.4007 13.2922C26.4007 14.0525 26.9681 16.0527 28.2461 16.0527C29.524 16.0527 30.0914 14.0525 30.0914 13.2922C30.0914 12.5514 29.6525 11.9116 29.0211 11.6181ZM28.2461 14.2932C28.0956 13.9974 27.9507 13.5404 27.9507 13.2923C27.9507 13.1294 28.0833 12.9969 28.2461 12.9969C28.409 12.9969 28.5414 13.1294 28.5414 13.2923C28.5414 13.5404 28.3966 13.9974 28.2461 14.2932Z"
      fill="white"
    />
    <path
      d="M6.57368 12.8469C7.00171 12.8469 7.34868 12.5 7.34868 12.0719V11.8141C7.34868 11.386 7.00171 11.0391 6.57368 11.0391C6.14564 11.0391 5.79868 11.386 5.79868 11.8141V12.0719C5.79868 12.5 6.14564 12.8469 6.57368 12.8469Z"
      fill="white"
    />
  </svg>
);

interface CollegeHatProps {
  heightOne?: number;
  heightTwo?: number;
  widthOne?: number;
  widthTwo?: number;
  boxheightone?: number;
  boxheighttwo?: number;
  boxwidthone?: number;
  boxwidthtwo?: number;
}

export const CollegeHat: React.FC<CollegeHatProps> = ({
  heightOne,
  heightTwo,
  widthOne,
  widthTwo,
  boxheightone,
  boxheighttwo,
  boxwidthone,
  boxwidthtwo,
}) => (
  <CollegeHatContainer>
    <CollegeHatSVG />
  </CollegeHatContainer>
);
