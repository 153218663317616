import { Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';

import { useAppSelector } from '../../store/store';
import { CustomAvatar } from '../../common';
import { Layout } from '../../components/Layout/Layout';
import { ROUTES } from '../../constants';
import { VendorHeader } from '../Vendors/VendorHeader';
import { VendorContainer } from '../Vendors/Vendors.style';
import { Flex, SliderContainer, TrackerContainer } from './Vendor.style';
import { Button } from '../../common';
import { useVendors } from '../../hooks/useVendors';
import { Loading } from '../../components/Loading';

export const Vendor = () => {
  const [limit, setLimit] = useState<number | number[]>(9);
  const { student, vendor } = useAppSelector((state) => state.user);
  const navigate = useNavigate();
  const { limitVendor, postLoading } = useVendors(student?.uid);

  useEffect(() => {
    setLimit(vendor.limit ?? 0);
    console.log(vendor);
  }, [vendor, navigate]);

  const onChange = (_: Event, newValue: number | number[]) => setLimit(newValue);

  const onSubmit = () => {
    limitVendor({
      limit,
      studentId: student.uid,
      vendorId: vendor.vendor.id,
    });
  };

  if (!vendor || !student) {
    return <Navigate to="/" />;
  }

  return (
    <Layout>
      <VendorHeader back={ROUTES.VENDORS} />
      {postLoading && <Loading />}
      <VendorContainer>
        <CustomAvatar image={<img src={`${vendor.vendor.photo}`} alt={vendor.vendor.name} />} />
        <Typography variant="h5">{vendor.vendor.name}</Typography>
      </VendorContainer>
      <TrackerContainer>
        <Flex>
          <Typography variant="h6">Limit</Typography>
          <Typography variant="h6">£{limit}</Typography>
        </Flex>
        <SliderContainer defaultValue={9} value={limit} onChange={onChange} />
        <Button content="Set weekly spending limit" width={35} onClick={onSubmit} />
      </TrackerContainer>
    </Layout>
  );
};
