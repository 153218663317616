import { Box, styled } from "@mui/material";
import React from "react";

const ShapeContainer = styled(Box)`
  height: 30%;
  width: 50%;
  position: relative;
  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    transform: scaleX(2.4);
  }
`;

export const Shape = () => (
  <ShapeContainer>
    <img src="shape.jpg" alt="" />
  </ShapeContainer>
)
