import { useState, useEffect } from 'react';
import { useQuery } from 'react-query';
import { useDispatch } from 'react-redux';

import { getUser } from '../api';
import { setUser } from '../store/user';

export const useStudents = () => {
  const [students, setStudents] = useState([]);
  const [currentUser, setCurrentUser] = useState({});
  const { data, isLoading, error } = useQuery('users', getUser);
  const dispatch = useDispatch();

  useEffect(() => {
    if (data) {
      setStudents(data.data.data.students);
      setCurrentUser(data.data.data.user);
      dispatch(setUser(data.data.data.user));
    }
  }, [data]);

  return {
    students,
    error,
    isLoading,
    currentUser,
  };
};
