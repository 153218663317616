import { Box } from '@mui/material';
import React from 'react';

export const Confirm = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      id="Combined Shape"
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M20.0001 12.0063V11.0763C20.0001 10.524 20.4478 10.0763 21.0001 10.0763C21.5523 10.0763 22.0001 10.524 22.0001 11.0763V12.0069C21.9973 16.8784 18.7906 21.1679 14.1189 22.5491C9.44731 23.9304 4.42359 22.0744 1.77211 17.9876C-0.87937 13.9009 -0.526988 8.55686 2.63816 4.85364C5.80332 1.15041 11.0272 -0.0298638 15.4771 1.95286C15.9815 2.17764 16.2083 2.76881 15.9835 3.27329C15.7587 3.77776 15.1675 4.00449 14.6631 3.77971C11.0223 2.15749 6.74818 3.12317 4.15851 6.15308C1.56884 9.18299 1.28053 13.5554 3.44992 16.8991C5.61932 20.2428 9.72963 21.7613 13.5519 20.6312C17.3741 19.5011 19.9978 15.9915 20.0001 12.0063ZM8.70717 10.2992L11.0001 12.5921L21.293 2.29918C21.6835 1.90866 22.3166 1.90866 22.7072 2.29918C23.0977 2.6897 23.0977 3.32287 22.7072 3.71339L11.7072 14.7134C11.3166 15.1039 10.6835 15.1039 10.293 14.7134L7.29296 11.7134C6.90243 11.3229 6.90243 10.6897 7.29296 10.2992C7.68348 9.90866 8.31664 9.90866 8.70717 10.2992Z"
      fill="white"
    />
  </svg>
);
