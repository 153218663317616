import { styled, Box } from '@mui/material';

export const AllTransactionsContainer = styled(Box)`
  height: 80vh;
  width: 100%;
  border-radius: 0.563rem;

  overflow: scroll;
  position: relative;
  scrollbar-width: none;
  z-index: 2;
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
  ::-webkit-scrollbar {
    display: none;
  }
`;

export const AllTransactionsBox = styled(Box)`
  border-radius: 2rem;
  padding: 0 2.75rem;
  overflow: hidden;
`;
