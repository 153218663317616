import { useState, useEffect } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import { addCreditCard, addFunds, getCreditCard } from '../api';
import { useNotification } from './useNotification';

interface Card {
  id: string;
  last4: string;
  type: string;
}

export const useCard = (schoolId: string) => {
  const [card, setCard] = useState<Card | null>(null);
  const { notify, notifyError } = useNotification();
  const queryClient = useQueryClient();
  const { data, isLoading } = useQuery(['card', schoolId], () => getCreditCard(schoolId));

  const mutation = useMutation(addCreditCard, {
    onSuccess: () => {
      notify({ message: 'Payment card added successfully' });
    },
    onError: (e: any) => {
      notifyError(e.message);
    },
  });

  const fundsMutation = useMutation(addFunds, {
    onSuccess: (data: any) => {
      notify({ message: 'Funds are added successfully' });
      queryClient.invalidateQueries(['studentTransactions'], {
        refetchActive: true,
      });
    },
    onError: (e: any) => {
      notifyError(e.message);
    },
  });

  useEffect(() => {
    if (data) {
      setCard(data.data.data.card);
    }
  }, [data]);

  return {
    isLoading,
    mutation,
    card,
    fundsMutation,
  };
};
