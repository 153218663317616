import axios from '../config/axiosConf';
import { SupportTicket } from '../pages/CreateSupport/CreateSupport';
import { ReplyMessage } from '../pages/SupportTicket/TicketReply';

export const login = async (data: { email: string; password: string }) => {
  const res = await axios({
    method: 'post',
    url: '/authorization/signin',
    data,
  });

  return res;
};

export const logout = async () => {
  try {
    const res = await axios({
      method: 'post',
      url: '/authorization/signout',
    });

    return res;
  } catch (e) {
    console.log(e);
  }
};

export const getUser = async () => {
  try {
    const res = await axios({
      method: 'get',
      url: '/user',
    });

    return res;
  } catch (e) {
    throw e;
  }
};

export const getStudentTransactions = async (studentId: string) => {
  const res = await axios({
    method: 'get',
    url: `/transaction/recent?studentId=${studentId}`,
  });

  return res;
};

export const getVendorsList = async (studentId: string) => {
  const res = await axios({
    method: 'get',
    url: `/vendor/limit/all?studentId=${studentId}`,
  });

  return res;
};

export const setVendorLimit = async (data: any) => {
  const res = await axios({
    method: 'post',
    url: 'vendor/limit',
    data,
  });

  return res;
};

export const createSupportTicket = async (data: SupportTicket) => {
  const res = await axios({
    method: 'post',
    url: '/support/ticket',
    data,
  });

  return res;
};

export const getAllSupportTickets = async (page: number = 1) => {
  const res = await axios({
    method: 'get',
    url: `/support/ticket/all?page=${page}`,
  });

  return res;
};

export const getSupportTicket = async (ticketId: string) => {
  const res = await axios({
    method: 'get',
    url: `/support/ticket?ticketId=${ticketId}`,
  });

  return res;
};

export const postSupportReply = async (data: ReplyMessage) => {
  const res = await axios({
    method: 'post',
    url: '/support/ticket/reply',
    data,
  });

  return res;
};

export const getCreditCard = async (schooldId?: string | null) => {
  if (schooldId) {
    const res = await axios({
      method: 'get',
      url: `/payment/card?schoolId=${schooldId}`,
    });

    return res;
  }
};
export const markAsPaid = async (data: any) => {
  const res = await axios({
    method: 'post',
    url: `mail/answer`,
    data,
  });

  return res;
};

export interface CardData {
  schoolId: string;
  cardNonce: string | null;
  verificationToken: string;
}

export const addCreditCard = async (data: CardData | null) => {
  const res = await axios({
    method: 'post',
    url: '/payment/card',
    data,
  });

  return res;
};

export interface AddFundsData {
  amount: number;
  verificationToken: string;
  studentId: string;
}

export const addFunds = async (data: AddFundsData | null) => {
  const res = await axios({
    method: 'post',
    url: '/user/funds',
    data,
  });

  return res;
};

export const getMails = async (userId: string | null, page: number) => {
  const res = await axios({
    method: 'get',
    url: `/mail`,
    params: {
      userId,
      page,
    },
  });
  return res;
};
