import { IconButton } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';

import { ShapeContainer } from '../pages/Login/Login.style';
import { VendorsBannerMsg } from '../pages/Vendors/Vendors.style';
import { BannerText } from './Logo';
import { HeaderContainer } from '../pages/Vendors/Vendors.style';
import { BackArrow } from '../svg/BackArrow';

export const ShapeHeader: React.FC<{ bannerText: string; back?: string; desc?: string }> = ({
  bannerText,
  back,
  desc,
}) => (
  <HeaderContainer>
    <Link to={back || '/'}>
      <IconButton size="large">
        <BackArrow />
      </IconButton>
    </Link>
    <VendorsBannerMsg>
      <BannerText variant="h4" bold={700}>
        {bannerText}
      </BannerText>
      {desc && <BannerText>{desc}</BannerText>}
    </VendorsBannerMsg>
  </HeaderContainer>
);
