import { useTheme } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';

import { login } from '../../api';
import { Button } from '../../common';
import { Layout } from '../../components/Layout/Layout';
import { Logo } from '../../components/Logo';
import { useAuth } from '../../hooks/useAuth';
import { useForm } from '../../hooks/useForm';
import { useMe } from '../../hooks/useMe';
import { useNotification } from '../../hooks/useNotification';
import { NewShape, NewShape2 } from '../../svg/LoginShape';
import { PasswordInput, TextInput } from './Input';
import { LoginContainer, ShapeContainer, FormContainer, AnimateImg } from './Login.style';

interface LoginCredentials {
  email: string;
  password: string;
}

export const Login = () => {
  const [animate, setAnimate] = useState(false);
  const theme = useTheme();
  const { values, onChange } = useForm<LoginCredentials>({
    email: '',
    password: '',
  });
  const navigate = useNavigate();
  const { setLocalStorage } = useMe();
  const { notifyError } = useNotification();
  const { loginUser } = useAuth();

  const mutation = useMutation(login, {
    onSuccess: (res) => {
      setAnimate(false);
      setLocalStorage(res.data.data.token, res.data.data.user.user);
      navigate('/');
    },
    onError: (error: any) => {
      notifyError('Incorrect email or password');
    },
  });

  useEffect(() => {
    if (mutation.isLoading) {
      setAnimate(true);
    } else {
      setAnimate(false);
    }
  }, [mutation.isLoading]);

  const onSubmit = async (event: any) => {
    event.preventDefault();
    // mutation.mutate(values);
    setAnimate(true);
    try {
      await loginUser(values.email, values.password);
      setAnimate(false);
    } catch (error) {
      console.log(error);
      notifyError('Incorrect email or password');
    }
  };

  return (
    <Layout bgColor={theme.palette.common.white}>
      <ShapeContainer animate={animate}>
        {animate ? <AnimateImg src="/collegeanim.png" alt="" /> : <Logo />}
        {/* <LoginShapeAnim animate={animate} /> */}
      </ShapeContainer>
      {/* <ShapeContainer>
        <NewShape />
      </ShapeContainer> */}
      <LoginContainer>
        {!animate && (
          <FormContainer component="form" onSubmit={onSubmit}>
            <TextInput
              label="Email"
              type="email"
              name="email"
              placeholder="Email"
              onChange={onChange}
              value={values.email}
              required
            />
            <PasswordInput
              label="Password"
              id="password"
              name="password"
              onChange={onChange}
              value={values.password}
              required
            />
            <Button type="submit" content="SIGN IN" width={55} />
          </FormContainer>
        )}
      </LoginContainer>
    </Layout>
  );
};
