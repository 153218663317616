import { styled, Box } from '@mui/material';
import { MuiIcon } from '../common/MuiIconStyled';
import LogoutIcon from '@mui/icons-material/Logout';

const Container = styled(Box)`
  display: flex;
  align-items: center;
  gap: 0.313rem;
  font-size: 1.2rem;
  cursor: pointer;
  position: absolute;
  right: 3.125rem;
  bottom: 3.125rem;
`;

export const LogoutFloationg = () => {
  return (
    <Container
      onClick={() => {
        window.localStorage.removeItem('ac_token');
        window.location.href = '/login';
      }}
    >
      <MuiIcon sx={{ color: '#0C84FC', fontSize: 29 }}>
        <LogoutIcon />
      </MuiIcon>
      Logout
    </Container>
  );
};
