import { Box, styled } from '@mui/material';
import React, { ReactElement } from 'react';

const AddCircleContainer = styled(Box)<{ height?: string; width?: string }>`
  height: ${({ height }) => height || '2.313rem'};
  width: ${({ width }) => width || '2.313rem'};
  display: flex;
  align-items: center;
  justify-content: flex-end;
  color: ${({ theme }) => theme.palette.common.white};
  cursor: pointer;
  position: absolute;
  top: 1rem;
  right: 1rem;
  z-index: 44444;

  @media (max-width: 768px) {
    p {
      font-size: 0.9rem;
    }
  }
`;

interface AddCircleProps {
  height?: string;
  width?: string;
  desc?: ReactElement;
  onClick?: () => void;
}

export const AddCircle: React.FC<AddCircleProps> = ({ height, width, desc, onClick }) => (
  <AddCircleContainer height={height} width={width} onClick={onClick}>
    {desc}
    <svg
      width="2.313rem"
      height="2.313rem"
      viewBox="0 0 2.313rem 2.313rem"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M3.08331 18.5C3.08331 27.0144 9.98559 33.9166 18.5 33.9166C27.0144 33.9166 33.9166 27.0144 33.9166 18.5C33.9166 9.98559 27.0144 3.08331 18.5 3.08331C9.98559 3.08331 3.08331 9.98559 3.08331 18.5ZM32.375 18.5C32.375 26.1629 26.1629 32.375 18.5 32.375C10.837 32.375 4.62498 26.1629 4.62498 18.5C4.62498 10.837 10.837 4.62498 18.5 4.62498C26.1629 4.62498 32.375 10.837 32.375 18.5ZM16.9583 24.6666C16.9583 25.5181 17.6485 26.2083 18.5 26.2083C19.3514 26.2083 20.0416 25.5181 20.0416 24.6666V20.0416H24.6666C25.5181 20.0416 26.2083 19.3514 26.2083 18.5C26.2083 17.6485 25.5181 16.9583 24.6666 16.9583H20.0416V12.3333C20.0416 11.4819 19.3514 10.7916 18.5 10.7916C17.6485 10.7916 16.9583 11.4819 16.9583 12.3333V16.9583H12.3333C11.4819 16.9583 10.7916 17.6485 10.7916 18.5C10.7916 19.3514 11.4819 20.0416 12.3333 20.0416H16.9583V24.6666Z"
        fill="white"
      />
      <mask
        id="mask0_2310_2906"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="3"
        y="3"
        width="31"
        height="31"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M3.08331 18.5C3.08331 27.0144 9.98559 33.9166 18.5 33.9166C27.0144 33.9166 33.9166 27.0144 33.9166 18.5C33.9166 9.98559 27.0144 3.08331 18.5 3.08331C9.98559 3.08331 3.08331 9.98559 3.08331 18.5ZM32.375 18.5C32.375 26.1629 26.1629 32.375 18.5 32.375C10.837 32.375 4.62498 26.1629 4.62498 18.5C4.62498 10.837 10.837 4.62498 18.5 4.62498C26.1629 4.62498 32.375 10.837 32.375 18.5ZM16.9583 24.6666C16.9583 25.5181 17.6485 26.2083 18.5 26.2083C19.3514 26.2083 20.0416 25.5181 20.0416 24.6666V20.0416H24.6666C25.5181 20.0416 26.2083 19.3514 26.2083 18.5C26.2083 17.6485 25.5181 16.9583 24.6666 16.9583H20.0416V12.3333C20.0416 11.4819 19.3514 10.7916 18.5 10.7916C17.6485 10.7916 16.9583 11.4819 16.9583 12.3333V16.9583H12.3333C11.4819 16.9583 10.7916 17.6485 10.7916 18.5C10.7916 19.3514 11.4819 20.0416 12.3333 20.0416H16.9583V24.6666Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_2310_2906)"></g>
    </svg>
  </AddCircleContainer>
);
