import { Avatar, styled } from '@mui/material';
import React from 'react';

const AvatarContainer = styled(Avatar)<{ bordercolor?: string }>`
  height: 10rem;
  width: 10rem;
  cursor: pointer;
  border: 3px solid ${({ bordercolor, theme }) => bordercolor || theme.color.red};

  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
`;

interface CustomAvatarProps {
  image?: any;
  bordercolor?: string;
  onClick?: () => void;
}

export const CustomAvatar: React.FC<CustomAvatarProps> = ({ image, bordercolor, onClick }) => (
  <AvatarContainer bordercolor={bordercolor} onClick={onClick}>
    {image || 'S'}
  </AvatarContainer>
);
