import React from 'react';
import { useNavigate } from 'react-router-dom';

import { Button } from '../../common';
import { ROUTES } from '../../constants';
import { CreditCard } from '../../svg/CreditCard';
import { CustomText } from '../StudentInfo/StudentInfo.style';
import { FlexCenter } from './PaymentMethod.style';

export const PaymentMethodNoCard: React.FC<{
  isLoading: boolean;
  noCard: boolean;
  noChosenSchool: boolean;
  openSetPayment: () => void;
}> = ({ isLoading, noCard, noChosenSchool, openSetPayment }) => {
  return (
    <>
      <FlexCenter column>
        <CreditCard />
        <CustomText fontsize={1.2}>
          {isLoading && 'Loading card information'}
          {noChosenSchool && 'Please choose the school'}
          {!noChosenSchool && noCard && 'No Payment Card Yet'}
        </CustomText>
      </FlexCenter>
      {!noChosenSchool && <Button width={27} content="Add payment card" onClick={openSetPayment} />}
    </>
  );
};
