import React from 'react';

import { BannerText } from '../../components/Logo';
import { CollegeHat } from '../../svg/CollegeHat';
import { CardFinance, NameLogoCard } from './StudentInfo.style';

interface StudentFinancesProps {
  name: string;
  availableBalance: number;
}

export const StudentFinances: React.FC<StudentFinancesProps> = ({ name, availableBalance }) => (
  <CardFinance>
    <NameLogoCard>
      <BannerText bold={700}>{name}</BannerText>
      <CollegeHat heightOne={41} heightTwo={22} />
    </NameLogoCard>
    <NameLogoCard height="62%" alignItems="flex-end" justifyContent="flex-end" column>
      <BannerText>Available balance</BannerText>
      <BannerText variant="h3" bold={700}>
        £{(availableBalance / 100).toFixed(2)}
      </BannerText>
    </NameLogoCard>
  </CardFinance>
);
