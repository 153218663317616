import React from 'react';

export const Card = () => {
  return (
    <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_597_1604)">
        <path
          d="M20.9706 3.38232H2.02941C0.910394 3.38232 0 4.29272 0 5.41174V17.5882C0 18.7073 0.910394 19.6176 2.02941 19.6176H20.9706C22.0896 19.6176 23 18.7073 23 17.5882V5.41174C23 4.29272 22.0896 3.38232 20.9706 3.38232ZM2.02941 4.73527H20.9706C21.3436 4.73527 21.6471 5.03873 21.6471 5.41174V6.08821H1.35294V5.41174C1.35294 5.03873 1.65641 4.73527 2.02941 4.73527ZM21.6471 7.44115V10.147H1.35294V7.44115H21.6471ZM20.9706 18.2647H2.02941C1.65641 18.2647 1.35294 17.9613 1.35294 17.5882V11.5H21.6471V17.5882C21.6471 17.9613 21.3436 18.2647 20.9706 18.2647Z"
          fill="#0C84FC"
        />
        <path d="M2.70581 12.853H9.47052V14.206H2.70581V12.853Z" fill="#0C84FC" />
        <path d="M2.70581 15.5588H7.44111V16.9118H2.70581V15.5588Z" fill="#0C84FC" />
        <path
          d="M16.2354 16.9119H20.2942V12.853H16.2354V16.9119ZM17.5883 14.206H18.9412V15.5589H17.5883V14.206Z"
          fill="#0C84FC"
        />
      </g>
      <defs>
        <clipPath id="clip0_597_1604">
          <rect width="23" height="23" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
