import { Box, styled } from '@mui/material';

export const TermsContainer = styled(Box)`
  padding: 20vh 10vw;
  width: 100%;
  overflow-y: auto;
`;

export const StyledTextContainer = styled(Box)`
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  padding: 2.563rem 4.688rem;
  position: relative;
`;
