import React, { useRef } from 'react';
import { useNavigate } from 'react-router-dom';

import { ListItem } from '../../common';
import { Layout } from '../../components/Layout/Layout';
import { Loading } from '../../components/Loading';
import { ShapeHeader } from '../../components/ShapeHeader';
import { ROUTES } from '../../constants';
import { useSupport } from '../../hooks/useSupport';
import { AddCircle } from '../../svg/AddCircle';
import { CustomText } from '../StudentInfo/StudentInfo.style';
import { SupportItemsContainer } from './Support.style';
import { SupportItem } from './SupportItem';
import { groupByDate } from '../../utils/dateSeparate';

export const Support = () => {
  const navigate = useNavigate();
  const { tickets, isLoading } = useSupport(1);
  const ref: any = useRef();

  if (isLoading) {
    return <Loading />;
  }

  return (
    <Layout onScroll={(e: any) => (ref.current.scrollTop = ref.current.scrollTop + e.deltaY)}>
      <ShapeHeader bannerText="Support" back={ROUTES.SETTINGS} />
      <AddCircle
        width="44%"
        onClick={() => navigate(ROUTES.CREATE_SUPPORT)}
        desc={
          <CustomText fontsize={1.1} bold={600} margin="0 4%">
            Create Support Ticket
          </CustomText>
        }
      />
      <SupportItemsContainer ref={ref}>
        {groupByDate(tickets).map((items) => (
          <>
            <ListItem bgcolor>
              <CustomText fontsize={1.26} bold={700}>
                {items.date}
              </CustomText>
            </ListItem>
            {items.transactions.map((item: any) => (
              <SupportItem
                key={item.id}
                name={item.createdBy.name}
                active={item.resolved}
                onClick={() => navigate(`${ROUTES.SUPPORT_TICKET}/${item.id}`)}
                subject={item.lastMessage.subject}
                image={item.createdBy.imageUrl}
              />
            ))}
          </>
        ))}
      </SupportItemsContainer>
    </Layout>
  );
};
