import { styled, SvgIcon, SvgIconProps } from "@mui/material";

const StyledSvg = styled(SvgIcon)`
  svg, path {
    height: 100%;
    width: 100%;
  }
`;

export const MuiIcon: React.FC<SvgIconProps> = ({
  children,
  ...rest
}) => (
  <StyledSvg {...rest}>
    {children}
  </StyledSvg>
)
