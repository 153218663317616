import { capitalize } from "@mui/material";

export const toCamelCase = (word: string) =>
  word.split(' ').map((item: string) => item.toLowerCase()).join('');

export const toDashSpace = (word: string) => word.toLowerCase().split(' ').join('-');

export const toSentence = (word: string) => word.split('-').map((a: string) => capitalize(a)).join(' ');

export const getPath = (word: string) => word.slice(word.lastIndexOf('/') + 1);
