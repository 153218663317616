import React from "react";

interface MailProps {
  height?: string;
  width?: string;
  fill?: string;
}

export const Mail: React.FC<MailProps> = ({
  height,
  width,
  fill,
}) => (
  <svg width={width || "33"} height={height || "25"} viewBox={`0 0 ${height || 33} ${width || 25}`} fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M2.90039 0.898438H30.0996C31.7021 0.898438 33 2.20439 33 3.79883V21.2012C33 22.8051 31.6926 24.1016 30.0996 24.1016H2.90039C1.3113 24.1016 0 22.8106 0 21.2012V3.79883C0 2.20722 1.29274 0.898438 2.90039 0.898438ZM3.30657 2.83203C3.89916 3.42146 14.097 13.5656 14.4491 13.9158C14.997 14.4637 15.7253 14.7653 16.5 14.7653C17.2747 14.7653 18.003 14.4636 18.5527 13.914C18.7895 13.6785 28.8748 3.64633 29.6934 2.83203H3.30657ZM31.0664 20.8076V4.1936L22.7108 12.5052L31.0664 20.8076ZM29.6922 22.168L21.3399 13.8688L19.9181 15.2831C19.0051 16.1961 17.7912 16.6989 16.5 16.6989C15.2088 16.6989 13.9949 16.1961 13.0837 15.2849L11.6601 13.8688L3.3078 22.168H29.6922ZM1.93359 20.8076L10.2892 12.5052L1.93359 4.1936V20.8076Z"
      fill={fill || "white"}
    />
  </svg>
);
