import { Avatar, Box, styled } from '@mui/material';

export const SupportItemsContainer = styled(Box)`
  width: 100%;
  padding: 30vh 10vw;
  overflow-y: auto;
  position: relative;

  @media (max-width: 768px) {
    padding: 1rem;
  }
`;

export const ListAvatar = styled(Avatar)`
  height: 2.75rem;
  width: 2.75rem;
`;

export const Marker = styled(Box)<{ bgColor: boolean }>`
  height: 75%;
  width: 0.375rem;
  border-radius: 0 0.375rem 0.375rem 0;
  background-color: ${({ theme, bgColor }) =>
    bgColor ? theme.palette.success.light : theme.palette.error.light};
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
`;

export const ListTextContainer = styled(Box)`
  margin: 1% 2%;

  a {
    text-decoration: none;
    color: ${({ theme }) => theme.palette.common.black};
    transition: 0.4s all;
    &:hover {
      color: ${({ theme }) => theme.color.commonBlue};
    }
  }
`;
