import { Button as MuiButton, styled } from '@mui/material';
import React, { ReactNode } from 'react';

const StyledButton = styled(MuiButton)<{
  width?: number;
  customColor?: string;
  bgColor?: string;
}>`
  width: ${({ width }) => (width ? `${width}%` : '100%')};
  border-radius: 0.563rem;
  box-shadow: 0 0.25rem 0.625rem rgba(0, 0, 0, 0.15);
  padding: 0.563rem;
  color: ${({ theme, customColor }) => (customColor ? customColor : theme.palette.common.white)};
  background: ${({ bgColor, theme }) => (bgColor ? bgColor : theme.color.blue)};
  &:hover {
    background: ${({ bgColor, theme }) => (bgColor ? bgColor : theme.color.blue)};
  }
`;

interface ButtonProps {
  content: string | ReactNode;
  width?: number;
  color?: string;
  bgColor?: string;
  loading?: boolean;
  [x: string]: any;
}

export const Button: React.FC<ButtonProps> = ({
  content,
  loading,
  width,
  color,
  bgColor,
  disabled,
  ...rest
}) => (
  <StyledButton width={width} disabled={loading} customColor={color} bgColor={bgColor} {...rest}>
    {loading ? 'loading' : content}
  </StyledButton>
);
