import { useTheme } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useDispatch } from 'react-redux';

import { getCreditCard, markAsPaid } from '../../api';
import { Button } from '../../common';
import { LoadingFull } from '../../components/Loading';
import { useMail } from '../../hooks/useMail';
import { useNotification } from '../../hooks/useNotification';
import { useSquare } from '../../hooks/useSquare';
import { useAppSelector } from '../../store/store';
import { changeMailOffer } from '../../store/user';
import { Flex } from '../AddCard/AddCard.style';
import { CustomText } from '../StudentInfo/StudentInfo.style';
import { MailPayContainer } from './MailInbox.style';

interface MailPayInterface {
  school: any;
  amount: number | null;
  mailId: string;
  offerResponse?: 'accepted' | 'denied';
}

export const MailPay: React.FC<MailPayInterface> = ({ school, amount, mailId, offerResponse }) => {
  const { student } = useAppSelector((state) => state.user);
  const [loading, setLoading] = useState(false);
  const [choosenCard, setChoosenCard] = useState('');
  const dispatch = useDispatch();
  const theme = useTheme();
  const { data } = useQuery(['card', school], () => getCreditCard(school));
  const { handleVerifyBuyerCharge, payments } = useSquare(school);
  const { answerMail } = useMail(student ? student.uid : null, 1);
  const { notifyError } = useNotification();

  const doPayment = async () => {
    if (!amount) return;
    setLoading(true);
    try {
      console.log(payments, choosenCard, amount.toString(), 'PAYMENT');
      const res = await handleVerifyBuyerCharge(payments, choosenCard, amount.toString());
      if (res.token) {
        const payed = await answerMail.mutateAsync({
          mailId,
          answer: 'accept',
          verificationToken: res.token,
        });
        dispatch(changeMailOffer('accepted'));
      }
      setLoading(false);
      return res.token;
    } catch (e) {
      setLoading(false);
      notifyError('Some error please Try again');
    }
  };

  const reject = async () => {
    setLoading(true);
    const rejected = await answerMail.mutateAsync({ mailId, answer: 'deny' });
    dispatch(changeMailOffer('denied'));
    setLoading(false);
  };

  useEffect(() => {
    if (data) {
      console.log(data);
      setChoosenCard(data?.data?.data?.card?.id);
    }
  }, [data]);

  return (
    <MailPayContainer>
      <Flex>
        <CustomText fontsize={1.6} bold={700}>
          PRICE:
        </CustomText>
        <CustomText fontsize={1.6} color={theme.color.commonBlue} bold={700} margin="0 0 0 0.5%">
          £{(amount && amount / 100)?.toFixed(2)}
        </CustomText>
      </Flex>
      {!offerResponse ? (
        <Flex justifyContent="space-between" sx={{ marginTop: '3%' }}>
          <Button
            content="REJECT"
            width={29}
            color={theme.palette.common.black}
            bgColor={theme.palette.grey[300]}
            onClick={reject}
          />
          <Button content="ACCEPT AND PAY" width={29} onClick={doPayment} />
        </Flex>
      ) : offerResponse === 'accepted' ? (
        <Flex justifyContent="flex-end" sx={{ marginTop: '3%' }}>
          <Button content="ACCEPTED" width={29} />
        </Flex>
      ) : (
        <Flex justifyContent="flex-end" sx={{ marginTop: '3%' }}>
          <Button
            content="REJECTED"
            width={29}
            color={theme.palette.common.black}
            bgColor={theme.palette.grey[300]}
          />
        </Flex>
      )}
      {loading && <LoadingFull />}
    </MailPayContainer>
  );
};
