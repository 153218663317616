import React from 'react';

import { Layout } from '../../components/Layout/Layout';
import { ShapeHeader } from '../../components/ShapeHeader';
import { ROUTES } from '../../constants';
import { CustomText } from '../StudentInfo/StudentInfo.style';
import { StyledTextContainer, TermsContainer } from './Terms.style';

export const Terms = () => (
  <Layout>
    <ShapeHeader bannerText="Terms & Conditions" back={ROUTES.SETTINGS} />
    <TermsContainer>
      <StyledTextContainer>
        <CustomText fontsize={1.6} bold={700}>
          Headline
        </CustomText>
        <CustomText>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt
          ut labore et dolore magna aliqua. Nibh mauris cursus mattis molestie a iaculis at. Sed
          blandit libero volutpat sed cras ornare arcu. Iaculis urna id volutpat lacus laoreet non
          curabitur gravida. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
          tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
          exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure
          dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.
          Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit
          anim id est laborum.
        </CustomText>
      </StyledTextContainer>
      <StyledTextContainer>
        <CustomText fontsize={1.6} bold={700}>
          Headline
        </CustomText>
        <CustomText>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt
          ut labore et dolore magna aliqua. Nibh mauris cursus mattis molestie a iaculis at. Sed
          blandit libero volutpat sed cras ornare arcu. Iaculis urna id volutpat lacus laoreet non
          curabitur gravida. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
          tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
          exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure
          dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.
          Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit
          anim id est laborum.
        </CustomText>
      </StyledTextContainer>
    </TermsContainer>
  </Layout>
);
