import LogoutIcon from '@mui/icons-material/Logout';
import { ReactElement } from 'react';
import { MuiIcon } from '../../common/MuiIconStyled';
import { Card } from '../../svg/Card';
import { Info } from '../../svg/Info';
import { SmallMail } from '../../svg/SmallMail';

export interface SettingsItem {
  desc: string;
  image?: ReactElement;
  route?: string;
}

export const settingsItems: SettingsItem[] = [
  {
    desc: 'Payment Method',
    route: 'paymentmethod',
    image: (
      <MuiIcon sx={{ color: '#0C84FC', fontSize: 29 }}>
        <Card />
      </MuiIcon>
    ),
  },
  {
    desc: 'Support',
    route: 'support',
    image: (
      <MuiIcon sx={{ color: '#0C84FC', fontSize: 29 }}>
        <SmallMail />
      </MuiIcon>
    ),
  },
  {
    desc: 'Terms and Conditions',
    route: 'terms',
    image: (
      <MuiIcon sx={{ color: '#0C84FC', fontSize: 29 }}>
        <Info />
      </MuiIcon>
    ),
  },
  {
    desc: 'Privacy Policy',
    route: 'privacy',
    image: (
      <MuiIcon sx={{ color: '#0C84FC', fontSize: 29 }}>
        <Info />
      </MuiIcon>
    ),
  },
  {
    desc: 'Logout',
    image: (
      <MuiIcon sx={{ color: '#0C84FC', fontSize: 29 }}>
        <LogoutIcon />
      </MuiIcon>
    ),
  },
];
