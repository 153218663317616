import { Box, useTheme } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';

import { ListItem } from '../../common';
import { CustomText } from './StudentInfo.style';
import { ROUTES } from '../../constants';
import { NameLogoCard, StudentTransactionsContainer } from './StudentInfo.style';
import { useStudentTransactions } from '../../hooks/useStudentTransactions';
import { formatDistance } from 'date-fns';
import moment from 'moment';

export const StudentTransactions: React.FC<{ studentId: string }> = ({ studentId }) => {
  const theme = useTheme();
  const { transactions } = useStudentTransactions(studentId);

  return (
    <StudentTransactionsContainer>
      <NameLogoCard>
        <CustomText fontsize={1.2} bold={700}>
          Recent activity
        </CustomText>
        <CustomText>
          <Link to={ROUTES.TRANSACTIONS}>
            <CustomText color="white" fontsize={1.2} bold={700}>
              View all
            </CustomText>
          </Link>
        </CustomText>
      </NameLogoCard>

      {transactions.length ? (
        transactions.map((transaction: any, index: number) => {
          return (
            <ListItem key={index}>
              <Box>
                <CustomText bold={700}>{transaction.name}</CustomText>
                <CustomText fontsize={0.75}>{moment.unix(transaction.date).fromNow()}</CustomText>
              </Box>
              <CustomText
                fontsize={1.4}
                color={
                  transaction.paidType === 'received'
                    ? theme.palette.success.light
                    : theme.palette.error.light
                }
              >
                {`${transaction.paidType === 'received' ? '+' : '-'} £${(
                  transaction.price / 100
                ).toFixed(2)}`}
              </CustomText>
            </ListItem>
          );
        })
      ) : (
        <CustomText>No recent transactions</CustomText>
      )}
    </StudentTransactionsContainer>
  );
};
