import { format } from 'date-fns';
import moment from 'moment';
import React from 'react';

import { ListItem } from '../../common';
import { DATE_FORMAT } from '../../constants';
import { CustomText } from '../StudentInfo/StudentInfo.style';
import { ListTextContainer } from '../Support/Support.style';
import { MailAvatar, MailDateContainer } from './Mail.style';

export interface MailItemProps {
  sender: string;
  subject?: string;
  image?: string;
  date?: number;
  imageUrl?: string;
  onClick?: () => void;
}

export const MailItem: React.FC<MailItemProps> = ({ sender, subject, onClick, imageUrl, date }) => (
  <ListItem justifycontent="flex-start" onClick={onClick}>
    <MailAvatar src={imageUrl || ''} />
    <ListTextContainer>
      <CustomText bold={700}>{sender}</CustomText>
      <CustomText bold={200}>{subject}</CustomText>
      {date && <MailDateContainer>{moment.unix(date).format('DD/MM/YYYY')}</MailDateContainer>}
    </ListTextContainer>
  </ListItem>
);
