import { Box, styled } from '@mui/material';

export const List = styled('ul')`
  display: flex;
  align-items: center;
  list-style: none;
  padding: 0 1.25rem;
  margin: 0;
`;

export const Page = styled(Box)<{ active: boolean }>`
  height: 2.563rem;
  width: 2.563rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.375rem;
  cursor: pointer;
  user-select: none;
  border: 1px solid ${({ theme, active }) => (active ? 'none' : theme.color.divider)};
  color: ${({ active, theme }) => active && theme.palette.common.white};
  background: ${({ active, theme }) => active && theme.background.blue};
`;

export const BtnNP = styled(Box)<{ left: number }>`
  height: 2.563rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.375rem 0.375rem;
  cursor: pointer;
  user-select: none;
  border: 1px solid ${({ theme }) => theme.color.divider};
  border-radius: ${({ left }) => (!left ? '0.563rem 0 0 0.563rem' : '0 0.563rem 0.563rem 0')};
  transition: all 0.4s;

  &:hover {
    font-weight: 700;
  }
`;
