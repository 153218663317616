import { Box, styled } from '@mui/material';

export const AddCardForm = styled(Box)`
  height: 100%;
  width: 29%;
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
  padding: 2.125rem;
  border-radius: 0.625rem;
  background-color: ${({ theme }) => theme.palette.common.white};
  box-shadow: 0px 0.25rem 0.625rem rgba(0, 0, 0, 0.15);

  @media (max-width: 768px) {
    width: 90%;

    button {
      width: 100%;
    }
  }
`;

export const Flex = styled(Box)<{ alignItems?: string; justifyContent?: string }>`
  width: 100%;
  display: flex;
  align-items: ${({ alignItems }) => alignItems || 'stretch'};
  justify-content: ${({ justifyContent }) => justifyContent || 'flex-start'};
`;
