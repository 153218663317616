import { useState, useEffect } from 'react';
import { useQuery } from 'react-query';

import { getStudentTransactions } from '../api';

export const useStudentTransactions = (studentId: string) => {
  const [transactions, setTransactions] = useState([]);
  const { data, isLoading, error, refetch } = useQuery(['studentTransactions', studentId], () =>
    getStudentTransactions(studentId),
  );

  useEffect(() => {
    if (data) {
      setTransactions(data.data.data);
    }
  }, [data]);

  return {
    transactions,
    isLoading,
    error,
    refetch,
  };
};
