import moment from 'moment';
import { Navigate } from 'react-router-dom';

import { ShapeHeader } from '../../components/ShapeHeader';
import { ROUTES } from '../../constants';
import { useAppSelector } from '../../store/store';
import { CustomText } from '../StudentInfo/StudentInfo.style';
import { Divider, MailInboxContainer } from './MailInbox.style';
import { MailPay } from './MailPay';

export const MailInbox = () => {
  const { mail } = useAppSelector((state) => state.user);
  if (!mail) return <Navigate to={ROUTES.MAIL} />;
  return (
    <>
      <ShapeHeader bannerText="Mail Inbox" back={ROUTES.MAIL} />
      <MailInboxContainer>
        <CustomText fontsize={1.4} bold={700} gutterBottom>
          From: {mail.from.name}
        </CustomText>
        {mail.date && (
          <CustomText fontsize={0.87} gutterBottom>
            {moment.unix(mail.date).format('DD/MM/YYYY HH:mm')}
          </CustomText>
        )}
        <CustomText margin="2% 0">Subject: {mail?.subject}</CustomText>
        <Divider />
        <CustomText margin="2% 0" gutterBottom>
          {mail.body}
        </CustomText>
        {mail.type === 'payRequest' && (
          <MailPay
            school={mail.schoolId}
            offerResponse={mail.offerResponse}
            amount={mail.paymentOffer}
            mailId={mail.id}
          />
        )}
      </MailInboxContainer>
    </>
  );
};
