import { Box, styled, Typography } from '@mui/material';
import React from 'react';

const LogoContainer = styled(Box)`
  width: 30%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-evenly;
  color: ${({ theme }) => theme.palette.common.white};
  position: absolute;
  top: 2.563rem;
  left: 50%;
  z-index: 9999;
  transform: translate(-50%, 0);
  div {
    color: ${({ theme }) => theme.palette.common.white};
  }
  @media (max-width: 1024px) {
    width: 100%;
  }
`;

const Flex = styled(Box)`
  display: flex;
  flex: 0 0 75%;
  margin-left: 6%;
`;

export const BannerText = styled(Typography)<{ bold?: number }>`
  font-weight: ${({ bold }) => bold || '400'};
  @media (max-width: 1248px) {
    font-size: 1rem;
  }
`;

export const Logo = () => (
  <LogoContainer>
    <img src="/logo.png" alt="" />
    <Flex>
      <BannerText variant="h4" bold={700}>
        Sign in
      </BannerText>
      <BannerText variant="h4">&nbsp;to your wallet</BannerText>
    </Flex>
  </LogoContainer>
);
