import { Box, FormControl, styled } from '@mui/material';
import emotionStyled from '@emotion/styled';

export const LoginContainer = styled(Box)`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
`;

export const ShapeContainer = styled(Box)<{ animate?: boolean }>`
  height: ${({ animate }) => (animate ? '100vh' : '30%')};
  flex: 1;
  color: ${({ theme }) => theme.palette.common.white};
  position: relative;
  transition: all 0.4s;
  z-index: 2;

  svg {
    position: absolute;
    top: 0;
    left: 0;
  }

  img {
    z-index: 9999;
  }
`;

export const AnimateImg = emotionStyled.img`
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translateX(-50%);
`;

export const LoginShapeCont = styled(Box)`
  @keyframes example {
    to: {
      height: 100vh;
    }
  }
`;

export const FormContainer = styled(Box)`
  height: 50%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  button {
    margin-top: 2%;
  }

  @media (min-width: 1248px) {
    width: 35%;
  }
`;

export const Control = styled(FormControl)`
  height: 4.438rem;
  width: 55%;
  padding: 0.563rem 0;

  & .MuiInput-underline {
    height: 100%;
    margin-top: 0;
  }
`;
