import { Box, Icon, styled } from '@mui/material';
import { SelectStudentBannerMsgContainer } from '../SelectStudent/SelectStudent.style';

export const VendorsBannerMsg = styled(SelectStudentBannerMsgContainer)`
  width: 100%;
  align-items: center;
  display: flex;
  z-index: 1;

  @media (max-width: 768px) {
    width: 75%;
    left: 4.188rem;

    p {
      font-size: 0.9rem;
    }
  }
`;

export const VendorContainer = styled(Box)`
  display: flex;
  align-items: center;
  flex: 2;
  gap: 1rem;

  h5 {
    margin-left: 2%;
  }

  @media (max-width: 768px) {
    padding: 0.875rem;

    h5 {
      margin-left: 6%;
    }
  }
`;

export const IconContainer = styled(Icon)`
  font-size: 2rem;
  color: ${({ theme }) => theme.palette.common.white};
  position: absolute;
  top: 1rem;
  left: 1rem;
  z-index: 1;

  svg {
    height: 100%;
    width: 100%;
  }
`;

export const VendorsContaienr = styled(Box)`
  height: 100vh;
  position: absolute;
  z-index: 0;
  width: 100vw;
  overflow: scroll;
  padding: 35vh 20%;
  display: flex;
  flex-direction: column;
  gap: 2rem;

  ::-webkit-scrollbar {
    display: none;
  }
`;

export const VendorItemContainer = styled(Box)`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
`;

export const TextContainer = styled(Box)`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const SliderContent = styled(Box)`
  flex: 3;
  display: flex;
  width: 100%;
`;
export const Slider = styled(Box)`
  display: flex;
  width: 100%;
  flex-direction: column;
`;

export const SliderText = styled(Box)`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

export const ButtonContainer = styled(Box)`
  display: flex;
  gap: 1rem;
`;

export const IconCircle = styled(Box)<{ invert?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  padding: 0.5rem;
  background-color: ${({ theme, invert }) => (!invert ? theme.color.blue : theme.color.lightGrey)};
  cursor: pointer;
`;

export const HeaderContainer = styled(Box)`
  display: flex;
  height: 20vh;
  align-items: center;
  z-index: 2;
  padding: 1.25rem;
  width: 100%;
`;
