import { Typography, useTheme } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { CustomAvatar } from '../../common';
import { Layout } from '../../components/Layout/Layout';
import { ROUTES } from '../../constants';
import { useAppSelector } from '../../store/store';
import { setStudent } from '../../store/user';
import { LoginShape, NewShape } from '../../svg/LoginShape';
import { Mail } from '../../svg/Mail';
import { ShapeContainer } from '../Login/Login.style';
import { AvatarInfo, AvatarsContainer, MailContainer } from './SelectStudent.style';
import { SelectStudentBannerMsg } from './SelectStudentBannerMsg';
import { Loading } from '../../components/Loading';
import { useStudents } from '../../hooks/useStudents';
import { LogoutFloationg } from '../../components/LogoutFloationg';

export const SelectStudent = () => {
  const [selected, setSelected] = useState(0);
  const theme = useTheme();
  const { students, isLoading } = useStudents();

  const { user } = useAppSelector((state) => state);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (!user?.student) {
      navigate(ROUTES.DASHBOARD);
    }
  }, [user?.student, navigate]);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <Layout bgColor={theme.color.lightGrey}>
      <ShapeContainer>
        <SelectStudentBannerMsg user={user.user} />
        <MailContainer onClick={() => navigate(ROUTES.MAIL)}>
          <Mail />
        </MailContainer>
      </ShapeContainer>
      <AvatarsContainer>
        {students.length > 0 &&
          students.map((student: any, index: number) => (
            <AvatarInfo
              key={index}
              onClick={() => {
                setSelected(index);
                dispatch(setStudent(student));
                navigate(ROUTES.STUDENT);
              }}
            >
              <CustomAvatar
                image={<img src={student.imageUrl} alt="" />}
                bordercolor={selected === index ? theme.color.blue : ''}
              />
              <Typography variant="h5">{student.name.split(' ')[0].toUpperCase()}</Typography>
              <Typography paragraph>{student?.college}</Typography>
            </AvatarInfo>
          ))}
        <LogoutFloationg />
      </AvatarsContainer>
    </Layout>
  );
};
