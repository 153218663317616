import { useState, useEffect } from 'react';
import { useInfiniteQuery } from 'react-query';
import Axios from '../config/axiosConf';

export const useAllTransactions = (studentId: string, activeTab: 'School bill' | 'wallet') => {
  const [transactions, setTransactions] = useState<any[]>([]);

  const getUrl = (activeTab: 'School bill' | 'wallet') => {
    if (activeTab === 'School bill') {
      return 'transaction/client/school-bill';
    }
    return 'transaction/wallet';
  };

  const { data, error, isLoading, isFetching, isFetchingNextPage, fetchNextPage, hasNextPage } =
    useInfiniteQuery(
      ['products', activeTab],
      async ({ pageParam = 1 }) => {
        const res = await Axios.get(
          `${getUrl(activeTab)}?studentId=${studentId}&page=${pageParam}`,
        );
        return res.data;
      },
      {
        getNextPageParam: (lastPage, pages) => pages.length + 1,
      },
    );

  useEffect(() => {
    if (data) {
      console.log(data);
      const arr = data.pages.map((item: any) => item.data.transactions);
      console.log(arr, 'hello');
      setTransactions(arr[0]);
    }
  }, [data]);
  return {
    transactions,
    data,
    error,
    isLoading,
    isFetching,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
  };
};
