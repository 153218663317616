import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Notification } from './components/Notification/Notification';
import AuthContextProvider from './context/authContext';
import { ReactQueryWrapper } from './components/ReactQueryWrapper';
import { AppRoutes } from './routes';

const App = () => {
  return (
    <ReactQueryWrapper>
      <BrowserRouter>
        <AuthContextProvider>
          <AppRoutes />
        </AuthContextProvider>
      </BrowserRouter>
      <Notification />
    </ReactQueryWrapper>
  );
};

export default App;
