import { useState, useEffect } from 'react';
import { USER, ACCESS_TOKEN, REFRESH_TOKEN, BEARER } from '../config/axiosConf';

interface UserType {
  name: string;
  email: string;
  isProfileCompleted: boolean;
  address: string | null;
  availableBalance: number;
  city: string;
  groups: any;
  parentId: any;
  paymentConnected: boolean;
  role: string;
  schools: any;
  schoolYear?: number;
  phoneNumber?: string;
  dateOfBirth?: any;
  imageUrl?: string;
}

export const useMe = () => {
  const [user, setUser] = useState<UserType | null>(null);

  useEffect(() => {
    if(user) {
      localStorage.setItem(USER, JSON.stringify(user));
    } else {
      localStorage.removeItem(USER);
    }
  }, [user]);

  const setLocalStorage = (token: string, userr?: UserType) => {
    localStorage.setItem(ACCESS_TOKEN, token);
    setUser(userr || null);
  }

  const logout = () => {
    localStorage.removeItem(ACCESS_TOKEN);
    localStorage.removeItem(REFRESH_TOKEN);
    localStorage.removeItem(BEARER);
    setUser(null);
  }

  const isLoggedIn = () => {
    if(localStorage.getItem(ACCESS_TOKEN)) {
      return true;
    }
    return false;
  }

  return {
    user,
    setLocalStorage,
    logout,
    isLoggedIn
  }
}
