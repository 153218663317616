import { Box, styled } from '@mui/material';

export const PaymentMethodContainer = styled(Box)`
  height: 50vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: column;
  position: absolute;
  top: 33%;

  @media (max-width: 768px) {
    button {
      width: 90%;
    }
  }
`;

export const FlexCenter = styled(Box)<{ column?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: ${({ column }) => (column ? 'column' : 'row')};
`;

export const Card = styled(Box)`
  height: 67%;
  width: 27%;
  padding: 1.313rem;
  background: ${({ theme }) => theme.color.blue};
  color: ${({ theme }) => theme.palette.common.white};
  box-shadow: 0px 0.25rem 0.625rem rgba(0, 0, 0, 0.15);
  border-radius: 0.625rem;
  position: relative;

  @media (max-width: 768px) {
    height: 50%;
    width: 90%;
  }
`;

export const CardLogoContainer = styled(Box)`
  position: absolute;
  bottom: 1.313rem;
  right: 1.313rem;
`;
