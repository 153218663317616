import { VisibilityOff, Visibility } from "@mui/icons-material";
import { InputLabel, Input, InputAdornment, IconButton } from "@mui/material";
import React, { useState } from "react";
import { Control } from "./Login.style";

interface PasswordInputProps {
  id?: string;
  label?: string;
  [x:string]: any;
}

export const TextInput: React.FC<PasswordInputProps> = ({
  id,
  label,
  ...rest
}) => (
  <Control variant="standard">
    <InputLabel htmlFor={id}>{label}</InputLabel>
    <Input
      id={id}
      type="text"
      {...rest}
    />
  </Control>
)

export const PasswordInput: React.FC<PasswordInputProps> = ({
  id,
  label,
  ...rest
}) => {
  const [showPassword, setShowPassword] = useState(false);
  return (
    <Control variant="standard">
      <InputLabel htmlFor={id}>{label}</InputLabel>
      <Input
        id={id}
        type={showPassword ? 'text' : 'password'}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={() => setShowPassword((prevState) => !prevState)}
            >
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        }
        {...rest}
      />
    </Control>
  )
}
