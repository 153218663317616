import { useState, useEffect } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { getMails, markAsPaid } from '../api';
import { useNotification } from './useNotification';

export const useMail = (userId: string | null, page: number) => {
  const [mails, setMails] = useState([]);
  const { data, isLoading, error } = useQuery(['mails', page, userId], () =>
    getMails(userId, page),
  );
  const { notify, notifyError } = useNotification();

  const queryClient = useQueryClient();

  const answerMail = useMutation(markAsPaid, {
    onSuccess: () => {
      queryClient.invalidateQueries(['mails', page, userId], {
        refetchActive: true,
      });
      notify({ message: 'Your answer is successfully sent!' });
    },
  });

  useEffect(() => {
    if (data) {
      setMails(data.data.data.mails);
    }
  }, [data]);

  return {
    mails,
    error,
    isLoading,
    setMails,
    answerMail,
  };
};
