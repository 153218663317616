import React from 'react';
import { useNavigate } from 'react-router-dom';

import { Button } from '../../common';
import { ROUTES } from '../../constants';
import { useCard } from '../../hooks/useCard';
import { CustomText } from '../StudentInfo/StudentInfo.style';
import { Card, CardLogoContainer } from './PaymentMethod.style';

interface PaymentCardAddedProps {
  name: string;
  schoolId: string;
  openSetPayment: () => void;
}

export const PaymentCardAdded: React.FC<PaymentCardAddedProps> = ({
  name,
  schoolId,
  openSetPayment,
}) => {
  const { card } = useCard(schoolId);

  return (
    <>
      <Card id={card?.id}>
        <CustomText fontsize={1.4} bold={700}>
          {name}
        </CustomText>
        <CustomText fontsize={1.4} margin="4% 0">
          **** **** **** {card?.last4}
        </CustomText>
        <CardLogoContainer></CardLogoContainer>
      </Card>
      <Button width={27} content="Change Card" onClick={openSetPayment} />
    </>
  );
};
