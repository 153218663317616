export enum ROUTES {
  LOGIN = '/login',
  DASHBOARD = '',
  STUDENT = '/student',
  VENDORS = '/vendors',
  VENDOR = '/vendor',
  USER = '/user',
  TRANSACTIONS = '/transactions',
  SETTINGS = '/settings',
  PAYMENT_METHOD = '/paymentmethod',
  ADD_CARD = '/addcard',
  SUPPORT = '/support',
  CREATE_SUPPORT = '/createsupport',
  SUPPORT_TICKET = '/support-ticket',
  TERMS = '/terms',
  MAIL = '/mail',
  MAIL_INBOX = '/inbox',
  PRIVACY = '/privacy',
  NOT_FOUND = '*',
}

export const DATE_FORMAT = 'dd.MM.yyyy hh:mm';
