import { Autocomplete, MenuItem } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { getCreditCard } from '../../api';
import { SelectField } from '../../common/Input';

import { Layout } from '../../components/Layout/Layout';
import { ShapeHeader } from '../../components/ShapeHeader';
import { ROUTES } from '../../constants';
import { useAppSelector } from '../../store/store';
import { AddCard } from '../AddCard/AddCard';
import { PaymentCardAdded } from './PaymentCardAdded';
import { PaymentMethodContainer } from './PaymentMethod.style';
import { PaymentMethodNoCard } from './PaymentMethodNoCard';

export const PaymentMethod = () => {
  const [openAddPayment, setOpenAddPayment] = useState(false);
  const [chosenSchool, setChosenSchool] = useState<any>(null);
  const { data, isLoading } = useQuery(['card', chosenSchool], () => getCreditCard(chosenSchool));
  const { user } = useAppSelector((state) => state.user);
  const openSetPayment = () => {
    setOpenAddPayment(true);
  };
  const closeSetPayment = () => {
    setOpenAddPayment(false);
  };

  useEffect(() => {
    if (user.schools.length === 1) {
      setChosenSchool(user.schools[0].id);
    }
  }, []);
  return (
    <>
      {!openAddPayment && (
        <Layout>
          <ShapeHeader bannerText="Payment method" back={ROUTES.SETTINGS} />

          <PaymentMethodContainer>
            <SelectField
              style={{ zIndex: 2 }}
              info="Select School"
              id="school"
              width="47%"
              alignitems="flex-start"
              margin="2% 0"
              name="schoolId"
              value={chosenSchool}
              onChange={(e: any) => {
                setChosenSchool(e.target.value);
              }}
            >
              <MenuItem value="" disabled>
                Select school
              </MenuItem>
              {user.schools.map((school: any) => (
                <MenuItem key={school.id} value={school.id}>
                  {school.name}
                </MenuItem>
              ))}
            </SelectField>
            {data?.data?.data?.card ? (
              <PaymentCardAdded
                name={user.name}
                schoolId={chosenSchool}
                openSetPayment={openSetPayment}
              />
            ) : (
              <PaymentMethodNoCard
                isLoading={isLoading}
                noCard={!data?.data?.data?.card}
                noChosenSchool={!chosenSchool}
                openSetPayment={openSetPayment}
              />
            )}
          </PaymentMethodContainer>
        </Layout>
      )}
      {openAddPayment && chosenSchool && (
        <AddCard closeSetPayment={closeSetPayment} chosenSchool={chosenSchool} />
      )}
    </>
  );
};
