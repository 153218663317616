import { Typography } from "@mui/material";
import React from "react";

import { Layout } from "../../components/Layout/Layout";
import { ShapeHeader } from "../../components/ShapeHeader";
import { ROUTES } from "../../constants";
import { StudentContainer } from "../StudentInfo/StudentInfo.style";

export const NotFound = () => (
  <Layout>
    <ShapeHeader bannerText="404 Page not found" back={ROUTES.DASHBOARD} />
    <StudentContainer>
      <Typography
        variant="h1"
      >
        404 Page not found go home
      </Typography>
    </StudentContainer>
  </Layout>
)
