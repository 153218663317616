import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { IconButton, useTheme } from '@mui/material';
import React, { useState } from 'react';
import { Link, Navigate, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { Layout } from '../../components/Layout/Layout';
import { Header, NameLogoCard, StudentContainer, StudentInfoContainer } from './StudentInfo.style';
import { StudentDetails } from './StudentDetails';
import { StudentFinances } from './StudentFinances';
import { Button } from '../../common';
import { Mail } from '../../svg/Mail';
import { StudentTransactions } from './StudentTransactions';
import { setStudent } from '../../store/user';
import { ROUTES } from '../../constants';
import { StudentModal } from '../../components/Modal/StudentModal';
import { useAppSelector } from '../../store/store';
import { Logo } from '../../svg/Logo';
import { BackArrow } from '../../svg/BackArrow';
import { Settings } from '../../svg/Settings';
import { LogoutFloationg } from '../../components/LogoutFloationg';

export const StudentInfo = () => {
  const [showModal, setShowModal] = useState(false);
  const theme = useTheme();
  const navigate = useNavigate();
  const state = useAppSelector((state) => state.user);
  const dispatch = useDispatch();

  if (!state.student) {
    return <Navigate to="/" />;
  }

  return (
    <Layout bgColor={theme.color.lightGrey}>
      {showModal && (
        <StudentModal
          open={showModal}
          onClose={() => setShowModal(false)}
          school={state?.student?.schools[0]}
          availableBalance={state.student?.availableBalance}
        />
      )}

      <Header>
        <IconButton
          onClick={() => {
            dispatch(setStudent(null));
            navigate('/');
          }}
        >
          <BackArrow />
        </IconButton>
        <Logo width="25rem" height="auto" />
        <Link to={ROUTES.SETTINGS}>
          <Settings />
        </Link>
      </Header>
      <StudentContainer>
        <StudentInfoContainer>
          <StudentDetails student={state?.student} />
          <StudentFinances
            name={state.student?.name}
            availableBalance={state.student?.availableBalance}
          />
          <NameLogoCard height="2.75rem" width="90%" sx={{ my: 4 }}>
            <Button
              content={<Mail fill={theme.color.commonBlue} />}
              width={44}
              bgColor={theme.palette.common.white}
              onClick={() => navigate(ROUTES.MAIL)}
            />
            <Button content="Add funds" width={44} onClick={() => setShowModal(true)} />
          </NameLogoCard>
          <Link to={ROUTES.VENDORS}>
            <NameLogoCard height="2.75rem" width="90%">
              <Button content="Restrictions" />
            </NameLogoCard>
          </Link>
        </StudentInfoContainer>
        {state.student && <StudentTransactions studentId={state.student.uid} />}
      </StudentContainer>
      <LogoutFloationg />
    </Layout>
  );
};
