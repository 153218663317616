import { Box, styled } from '@mui/material';
import { Flex } from '../AddCard/AddCard.style';

export const MailInboxContainer = styled(Box)`
  padding: 20vh 10vw;
  position: relative;

  @media (max-width: 768px) {
    padding: 1rem;

    button {
      width: 44%;
    }
  }
`;

export const Divider = styled(Box)`
  height: 1px;
  width: 100%;
  background-color: ${({ theme }) => theme.palette.grey[400]};
`;

export const MailPayContainer = styled(Flex)`
  flex-direction: column;
  margin-top: 3%;
`;
