import React from 'react';
import { RouteObject, Navigate, useRoutes } from 'react-router-dom';

import { ROUTES } from './constants';

import { AllTransactions } from './pages/AllTransactions/AllTransactions';
import { Dashboard } from './pages/Dashboard';
import { Login } from './pages/Login/Login';
import { SelectStudent } from './pages/SelectStudent/SelectStudent';
import { StudentInfo } from './pages/StudentInfo/StudentInfo';
import { User } from './pages/User';
import { Vendors } from './pages/Vendors/Vendors';
import { Vendor } from './pages/Vendor/Vendor';
import { Settings } from './pages/Settings/Settings';
import { PaymentMethod } from './pages/PaymentMethod/PaymentMethod';
import { Terms } from './pages/Terms/Terms';
import { Support } from './pages/Support/Support';
import { CreateSupport } from './pages/CreateSupport/CreateSupport';
import { MailPage } from './pages/MailPage/MailPage';
import { MailInbox } from './pages/MailInbox/MailInbox';
import { NotFound } from './pages/NotFound.tsx/NotFound';
import { SupportTicket } from './pages/SupportTicket/SupportTicket';
import { PrivacyPolicy } from './pages/PrivacyPolicy/PrivacyPolicy';

const getRoutes = (logged: boolean): RouteObject[] => [
  {
    path: ROUTES.LOGIN,
    element: <Login />,
  },
  {
    path: ROUTES.DASHBOARD,
    element: logged ? <Dashboard /> : <Navigate to={ROUTES.LOGIN} />,
    children: [
      {
        path: ROUTES.DASHBOARD,
        element: <SelectStudent />,
      },
      {
        path: ROUTES.STUDENT,
        element: <StudentInfo />,
      },
      {
        path: ROUTES.VENDORS,
        element: <Vendors />,
      },
      {
        path: ROUTES.VENDOR + '/:vendor',
        element: <Vendor />,
      },
      {
        path: ROUTES.USER,
        element: <User />,
      },
      {
        path: ROUTES.TRANSACTIONS,
        element: <AllTransactions />,
      },
      {
        path: ROUTES.SETTINGS,
        element: <Settings />,
      },
      {
        path: ROUTES.PAYMENT_METHOD,
        element: <PaymentMethod />,
      },
      {
        path: ROUTES.SUPPORT,
        element: <Support />,
      },
      {
        path: ROUTES.CREATE_SUPPORT,
        element: <CreateSupport />,
      },
      {
        path: `${ROUTES.SUPPORT_TICKET}/:id`,
        element: <SupportTicket />,
      },
      {
        path: ROUTES.PRIVACY,
        element: <PrivacyPolicy />,
      },
      {
        path: ROUTES.TERMS,
        element: <Terms />,
      },
      {
        path: ROUTES.MAIL,
        element: <MailPage />,
      },
      {
        path: ROUTES.MAIL_INBOX,
        element: <MailInbox />,
      },
    ],
  },
  {
    path: ROUTES.NOT_FOUND,
    element: <NotFound />,
  },
];

export const AppRoutes = () => {
  const routes = useRoutes(getRoutes(!!localStorage.getItem('user')));
  return <>{routes}</>;
};
