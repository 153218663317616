import { Avatar, styled, Typography } from '@mui/material';

export const MailAvatar = styled(Avatar)`
  height: 3.438rem;
  width: 3.438rem;
`;

export const MailDateContainer = styled(Typography)`
  font-size: 0.87rem;
  position: absolute;
  top: 0.563rem;
  right: 1.25rem;
`;
