import React from 'react';

import { BannerText } from '../../components/Logo';
import { SelectStudentBannerMsgContainer } from './SelectStudent.style';
import { UniqueSpaceText } from '../../common/Text';

interface SelectStudentBannerMsgProps {
  user?: any;
}

export const SelectStudentBannerMsg: React.FC<SelectStudentBannerMsgProps> = ({ user }) => (
  <SelectStudentBannerMsgContainer>
    <UniqueSpaceText>Hi {user?.name},</UniqueSpaceText>
    <BannerText variant="h4" bold={700}>
      welcome to Pupil wallet
    </BannerText>
    <BannerText variant="h5">
      Please select student to manage their wallet, check their
      <br />
      transactions, orders, and manage their purchases
    </BannerText>
  </SelectStudentBannerMsgContainer>
);
