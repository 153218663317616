import { Box, styled } from '@mui/material';

export const SettingsItemsContainer = styled(Box)`
  height: 90vh;
  width: 100%;
  padding: 0.25rem 6.5rem;
  z-index: 2;
  position: relative;

  a {
    text-decoration: none;
  }

  @media (max-width: 768px) {
    padding: 0.25rem 0.875rem;
  }
`;
