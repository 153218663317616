import { Box, CircularProgress, styled } from '@mui/material';
import React from 'react';

const LoadingFullContainer = styled(Box)`
  width: 100vw;
  position: fixed;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  z-index: 9999999;
`;

const LoadingContainer = styled(Box)`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: inherit;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9999999;
`;

export const Loading = () => (
  <LoadingContainer>
    <CircularProgress />
  </LoadingContainer>
);

export const LoadingFull = () => (
  <LoadingFullContainer>
    <CircularProgress />
  </LoadingFullContainer>
);
