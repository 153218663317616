import React from 'react';
import { CreditCardInput, SquarePaymentsForm } from 'react-square-web-payments-sdk';

import { Layout } from '../../components/Layout/Layout';
import { ShapeHeader } from '../../components/ShapeHeader';
import { ROUTES } from '../../constants';
import { useSquare } from '../../hooks/useSquare';
import { PaymentMethodContainer } from '../PaymentMethod/PaymentMethod.style';
import { AddCardForm } from './AddCard.style';

export const AddCard: React.FC<{ chosenSchool: any; closeSetPayment: () => void }> = ({
  chosenSchool,
  closeSetPayment,
}) => {
  const { applicationId, locationId, cardTokenizeResponseReceived } = useSquare(chosenSchool);

  return (
    <Layout>
      <ShapeHeader bannerText="Add Card" />

      <SquarePaymentsForm
        applicationId={applicationId}
        locationId={locationId}
        cardTokenizeResponseReceived={cardTokenizeResponseReceived}
      >
        <PaymentMethodContainer>
          {/* <AddCardForm id="card-container">
            <InputField id="sq-card-number" info="Card Number" width="100%" />
            <Flex justifyContent="space-between">
              <InputField id="sq-expiry-date" info="Expiry Date" width="44%" />
              <InputField id="sq-cvv" info="CVC" width="44%" />
              <InputField id="sq-postalCode" info="ZIP" width="44%" />
            </Flex>
            <Button
              content="Add card"
              id="pay-with-card"
              type="button"
            />
          </AddCardForm> */}

          <AddCardForm onSubmit={() => console.log('Submiting')}>
            <CreditCardInput />
          </AddCardForm>
        </PaymentMethodContainer>
      </SquarePaymentsForm>
    </Layout>
  );
};
