import React from "react";

import { ListItem } from "../../common";
import { CustomText } from "../StudentInfo/StudentInfo.style";
import { ListAvatar, ListTextContainer, Marker } from "./Support.style";

interface SupportItemProps {
  active: boolean;
  name: string;
  subject: string;
  onClick?: () => void;
  image?: string;
}

export const SupportItem: React.FC<SupportItemProps> = ({
  active,
  name,
  subject,
  onClick,
  image,
}) => (
  <ListItem justifycontent="flex-start" onClick={onClick}>
    <Marker bgColor={active} />
    <ListAvatar src={image || ''} />
    <ListTextContainer>
      <CustomText bold={700}>{name}</CustomText>
      <CustomText bold={200}>Subject: {subject}</CustomText>
    </ListTextContainer>
  </ListItem>
)
