import { useTheme } from '@mui/material';
import React from 'react';
import { NewShape } from '../../svg/LoginShape';

import { LayoutContainer } from './Layout.style';

interface LayoutProps {
  bgColor?: string;
  padding?: string;
  noScroll?: string;
  onScroll?: (e: any) => void;
}

export const Layout: React.FC<LayoutProps> = ({
  children,
  padding,
  noScroll,
  bgColor,
  onScroll,
}) => {
  const theme = useTheme();

  return (
    <LayoutContainer
      bgColor={bgColor || theme.color.lightGrey}
      padding={padding}
      noScroll={noScroll}
      onWheel={(e) => onScroll?.(e)}
    >
      <NewShape />
      {children}
    </LayoutContainer>
  );
};
