import { Box, styled } from '@mui/material';

export const AvatarsContainer = styled(Box)`
  flex: 1;
  position: absolute;
  width: 100vw;
  height: 70vh;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 3rem;
  z-index: 2;
`;

export const AvatarInfo = styled(Box)`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 0 4%;
  & .MuiAvatar-root {
    margin-bottom: 12%;
  }
  h5 {
    font-weight: 700;
    margin: 0 2%;
  }
`;

export const SelectStudentBannerMsgContainer = styled(Box)`
  width: 100%;
  color: ${({ theme }) => theme.palette.common.white};
  top: 1.25rem;
  left: 0px;
  z-index: 9999;
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  p {
    margin-top: 1%;
  }
`;

export const MailContainer = styled(Box)`
  height: 2.063rem;
  width: 2.063rem;
  cursor: pointer;
  position: absolute;
  top: 1.25rem;
  right: 2.563rem;
  z-index: 10;
  cursor: pointer;
`;
