import React from 'react';

export const BackArrow = () => {
  return (
    <svg width="13" height="21" viewBox="0 0 13 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.02568 11.2495L10.4693 20.69C10.8837 21.1033 11.555 21.1033 11.9704 20.69C12.3848 20.2766 12.3848 19.6053 11.9704 19.1919L3.2759 10.5005L11.9694 1.80913C12.3838 1.3958 12.3838 0.724439 11.9694 0.31006C11.555 -0.103272 10.8826 -0.103272 10.4682 0.31006L1.02463 9.75043C0.616616 10.1595 0.616616 10.8414 1.02568 11.2495Z"
        fill="white"
      />
    </svg>
  );
};
