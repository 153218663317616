import { styled, Box } from '@mui/material';

export const LayoutContainer = styled(Box)<{
  bgColor?: string;
  padding?: string;
  noScroll?: string;
}>`
  height: 100vh;
  width: 100%;
  padding: ${({ padding }) => padding};
  background-color: ${({ bgColor }) => bgColor || 'initial'};
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: column;

  @media (max-width: 768px) {
    overflow-y: ${({ noScroll }) => noScroll || 'auto'};
  }
`;
