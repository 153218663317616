import { ChevronLeft } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';

import { BannerText } from '../../components/Logo';
import { BackArrow } from '../../svg/BackArrow';
import {
  Header,
  TransactionTabContainer,
  TransactionTab,
  TransactionHeader,
} from '../StudentInfo/StudentInfo.style';

interface AllTransactionsHeaderProps {
  text: string;
  lnk: string;
  width?: number;
  activeTab?: string;
  setActiveTab?: any;
  noTabs?: boolean;
}

export const AllTransactionsHeader: React.FC<AllTransactionsHeaderProps> = ({
  text,
  lnk,
  width,
  activeTab,
  setActiveTab,
  noTabs,
}) => (
  <Header>
    <Link to={lnk}>
      <IconButton>
        <BackArrow />
      </IconButton>
    </Link>
    <TransactionHeader>
      <BannerText variant="h3" bold={700} color="white">
        {text}
      </BannerText>
      {!noTabs && (
        <TransactionTabContainer>
          <TransactionTab active={activeTab === 'wallet'} onClick={() => setActiveTab('wallet')}>
            Transactions
          </TransactionTab>
          <TransactionTab
            active={activeTab === 'School bill'}
            onClick={() => setActiveTab('School bill')}
          >
            School bill
          </TransactionTab>
        </TransactionTabContainer>
      )}
    </TransactionHeader>
    <div></div>
  </Header>
);
