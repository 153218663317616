import { Modal, Paper, styled } from '@mui/material';
import { Flex } from '../../pages/AddCard/AddCard.style';

export const StudentModalContainer = styled(Modal)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const StudentModalContent = styled(Paper)`
  width: 19%;
  border-radius: 0.625rem;
  box-shadow: 0px 0.25rem 0.625rem rgba(0, 0, 0, 0.15);
  position: relative;
  height: fit-content;

  &:nth-of-type(2) {
    height: 35%;
  }

  @media (max-width: 768px) {
    width: 90%;
  }
`;

export const FieldsContainer = styled(Flex)<{ height?: number }>`
  height: ${({ height }) => `${height}%`};
  flex-direction: column;
  padding: 1.188rem;
  gap: 1rem;
`;
