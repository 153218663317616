import moment from 'moment';

export const groupByDate = (data: any) => {
  const groups = data.reduce((dates: any, transaction: any) => {
    const date = moment.unix(transaction.date).format('MMM/DD/YYYY');
    if (!dates[date]) {
      dates[date] = [];
    }
    dates[date].push(transaction);
    return dates;
  }, {});

  return Object.keys(groups).map((date) => {
    return {
      date,
      transactions: groups[date],
    };
  });
};
